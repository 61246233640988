const apiUrl = process.env.REACT_APP_API_URL;

const routes = {
    auth: {
        login: `${apiUrl}/auth/login`,
        logout: `${apiUrl}/auth/logout`,
        forgotPassword: `${apiUrl}/auth/forgot/password`,
        resetPassword: `${apiUrl}/auth/reset/password`,
    },
    profile: {
        my: `${apiUrl}/users/me`,
        profilePicture: `${apiUrl}/users/me/picture/`,
        picture: (id: string) => `${apiUrl}/users/picture/${id}`,
        account: `${apiUrl}/users/me/account`,
    },
    users: {
        list: `${apiUrl}/users/seller`,
        createUser: `${apiUrl}/users`,
        createSeller: `${apiUrl}/users/seller`,
        editUser: (id: string) => `${apiUrl}/users/${id}`,
        editSeller: (id: string) => `${apiUrl}/users/seller/${id}`,
        deleteUser: (id: string) => `${apiUrl}/users/${id}`,
        notifySeller: (id: string) => `${apiUrl}/users/notify/${id}`,
        sellerAnalytics: `${apiUrl}/users/seller/analytics`,
        myUtmData: `${apiUrl}/users/utm/me`,
        utmData: (id: string) => `${apiUrl}/users/utm/${id}`
    },
    products: {
        list: `${apiUrl}/products`,
        single: (id: string) => `${apiUrl}/products/${id}`,
        picture: (id: string) => `${apiUrl}/products/pictures/${id}`,
        ugc: (id: string) => `${apiUrl}/products/ugc/${id}`,
        withdraw: (id: string) => `${apiUrl}/products/withdraw/${id}`,
    },
    agencies: {
        list: `${apiUrl}/agencies`,
        single: (id: string) => `${apiUrl}/agencies/${id}`, 
    },
    payments: {
        onboardUser: `${apiUrl}/payments`,
        totalSales: (id: string) => `${apiUrl}/payments/total/${id}`,
    },
};

export default routes;
