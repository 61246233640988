import React, { useRef, useState } from "react";
import { cn } from "@/libs/utils";
import { Button } from "@/components/ui/button";
import { ImageIcon, UploadCloud, Trash2 } from "lucide-react";

interface FileUploadProps extends React.HTMLAttributes<HTMLDivElement> {
  onUpload: (file: File | null) => void;
  preview?: string; // Preview image
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onUpload,
  preview,
  className,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(
    preview || null
  );

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    if (file) {
      const reader = new FileReader();
      reader.onload = () => setPreviewImage(reader.result as string);
      reader.readAsDataURL(file);
    }

    onUpload(file);
  };

  const handleRemove = () => {
    setPreviewImage(null);
    onUpload(null);
  };

  return (
    <div
      className={cn(
        "cursor-pointer flex flex-col items-center gap-2 p-4 border border-dashed rounded-full w-40 h-40 justify-center relative",
        className
      )}
      {...props}
    >
      <input
        type="file"
        ref={inputRef}
        className="hidden"
        onChange={handleFileChange}
        accept="image/*"
      />

      {previewImage ? (
        <>
          <img
            src={preview}
            alt="Preview"
            className="w-full h-full object-cover rounded-full"
          />
          <Button
            onClick={handleRemove}
            variant="destructive"
            size="sm"
            className="absolute top-2 right-2 p-1 rounded-full"
          >
            <Trash2 className="w-4 h-4" />
          </Button>
        </>
      ) : (
        <div
          onClick={handleClick}
          className="flex flex-col items-center gap-2 text-gray-500"
        >
          <ImageIcon className="w-10 h-10" />
          <p className="text-sm">Click to upload</p>
          <Button variant="outline" className="mt-2 flex gap-2">
            <UploadCloud className="w-5 h-5" />
          </Button>
        </div>
      )}
    </div>
  );
};
