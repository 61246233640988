import { FunctionComponent, useEffect, useState } from "react";
import Table from "@/components/table";
import PageTitle from "@/components/pageTitle";
import {
  createAgency,
  deleteAgency,
  getAgency,
  getAllAgencies,
  updateAgency,
} from "@/communication/agencies";
import { AgencyDto } from "@/global/dtos/agency.dto";
import useLoading from "@/hooks/useLoading";
import Button from "@/components/buttons";
import useToggle from "@/hooks/useToggle";
import { useTouchField } from "@/hooks/useTouchField";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { StyledPageHeader } from "@/global/styles/styles";

const AgencyList: FunctionComponent = () => {
  const { showLoading, hideLoading } = useLoading();
  const [agencyList, setAgencyList] = useState<AgencyDto[] | null>(null);
  const { isFieldTouched, handleFieldTouch } = useTouchField<FormValues>();
  const [agencyId, setAgencyId] = useState<string | null>(null);
  const navigate = useNavigate();

  type FormValues = {
    name: string | undefined;
    bankAccount?: string | undefined;
    routingNumber?: string | undefined;
    newBankAccount?: string | undefined;
    newRoutingNumber?: string | undefined;
  };

  const [tmpFormValues, setTmpFormValues] = useState<FormValues>({
    name: undefined,
    bankAccount: undefined,
    routingNumber: undefined,
    newBankAccount: undefined,
    newRoutingNumber: undefined,
  });

  const goToTheAgency = (id: string): void => {
    navigate(`/agencies/${id}`);
  };

  const handleChange = (fieldName: keyof FormValues, value: string): void => {
    handleFieldTouch(fieldName);
    setTmpFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    if (fieldName === "name")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["name"]: value,
      }));
    if (fieldName === "newBankAccount")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["newBankAccount"]: value,
      }));
    if (fieldName === "newRoutingNumber")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["newRoutingNumber"]: value,
      }));
  };

  const handleRowClick = (e): any => {
    goToTheAgency(e.id);
    console.log(e);
  };

  useEffect(() => {
    getAllAgencies().then((res: any) => {
      setAgencyList && setAgencyList(res.data);
    });
  }, []);

  const handleCreate = (): void => {
    showLoading();
    createAgency(tmpFormValues)
      .then(async () => {
        getAllAgencies().then((res: any) => {
          setAgencyList && setAgencyList(res.data);
        });
        hideLoading();
        toast.success("Agency added!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["name"]: "",
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["bankAccount"]: "",
        }));
        setTmpFormValues((prevValues) => ({
          ...prevValues,
          ["routingNumber"]: "",
        }));
      })
      .catch((error: any) => {
        hideLoading();
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
  };

  const handleUpdate = (): void => {
    showLoading();
    if (agencyId) {
      updateAgency(agencyId, tmpFormValues)
        .then(async () => {
          getAllAgencies().then((res: any) => {
            setAgencyList && setAgencyList(res.data);
          });
          setAgencyId(null);
          hideLoading();
          toast.success("Agency updated!", {
            position: "bottom-center",
            autoClose: 1500,
          });

          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["name"]: "",
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["bankAccount"]: "",
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["routingNumber"]: "",
          }));
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          setAgencyId(null);
          console.log(error);
        });
    }
  };

  const delAgency = (): void => {
    if (agencyId) {
      showLoading();
      deleteAgency(agencyId)
        .then(async (res: any) => {
          toast.success("Agency successfully deleted!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          hideLoading();
          navigate(`/agencies`);
          setAgencyId(null);
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          setAgencyId(null);
          console.log(error);
        });
    }
  };

  const handleDelete = (agencyId: string): void => {
    setAgencyId(agencyId);
  };

  const handleEdit = (agencyId: string): void => {
    setAgencyId(agencyId);
    if (agencyId) {
      showLoading();
      getAgency(agencyId)
        .then((res: any) => {
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["name"]: res.data?.name,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["bankAccount"]: res.data?.bankAccount,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["routingNumber"]: res.data?.routingNumber,
          }));
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="page">
        <StyledPageHeader>
          <PageTitle title="Agencies" />
          <Button onClick={() => console.log("add agency")}>Add Agency</Button>
        </StyledPageHeader>
        {/* <Table
          items={
            agencyList?.map((agency) => ({
              id: agency.id,
              name: agency.name,
            })) || []
          }
          columns={[{ accessorKey: "name", header: "Name" }]}
          handleRowClick={(e) => handleRowClick(e)}
          handleDelete={(e) => {
            handleDelete(e.id);
          }}
          handleEdit={(e) => {
            handleEdit(e.id);
          }}
        /> */}
      </div>
    </>
  );
};

export default AgencyList;
