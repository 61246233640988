export enum UserStatus {
    NEW = 'NEW', // When the seller is just created (not confirmed)
    CREATED='CREATED', // When user is created by admin, but not confirmed by seller
    INCOMPLETE='INCOMPLETE', //Seller didn't complete his account, bank info is missing
    INVALID = 'INVALID', //Seller's bank info has an error
    READY = 'READY', // When seller is approved by Admin
    REJECTED = 'REJECTED', // When seller is rejected by Admin
}

export enum UserCommissionType {
    TIERED_V1 = 'TIERED_V1',
    TIERED_V2 = 'TIERED_V2',
    CUSTOM_50 = "50/50",
};

export type UserDto = {
    id: string;
    email: string;
    displayName: string;
    instaHandle?: string;
    tiktokHandle?: string;
    firstName?: string;
    lastName?: string;
    status?: string;
    createdAt: Date;
    updatedAt: Date;
    enabled: boolean;
    role: number;
    commissionType: string;
};

export type SellerAnalyticsDto = {
    id: string;
    fullName: string;
    totalSold: number;
    totalEarned: number;
    commissionType: string;
    pendingPayout: string;
    payedOut: string;
};

export type UpdateSellerDto = {
    id: string;
    email?: string;
    displayName?: string;
    instaHandle?: string;
    tiktokHandle?: string;
    firstName?: string;
    lastName?: string;
};