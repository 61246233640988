import { FunctionComponent, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import useGlobalState from "@/store/global";
import useWindowSize from "@/hooks/useResize";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { useNavbarItems } from "../useNavItems";
import { StyledCustomLink, StyledVerticalNavbar } from "./style";
import LogoImg from "@/assets/images/logo.png";
import useUserStore from "@/store/user";
import { UserStatus } from "@/global/dtos/user.dto";
import { ReactComponent as LinkIcon } from "@/assets/icons/linkIcon.svg";
import { RoleCodes } from "@/global/roles";

type Props = {
  className?: string;
};

const VerticalNavbar: FunctionComponent<Props> = ({ className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const navbarItems = useNavbarItems();
  const { isNavbarCollapsed } = useGlobalState();
  const size = useWindowSize();
  const currentPage = useLocation().pathname;
  const { role, status } = useUserStore();

  const [isMobileWidth, setIsMobileWidth] = useState(
    !!size.width && size?.width < 1024
  );

  const handleCloseMenu = (): void => {
    isMobileWidth && useGlobalState.setState({ isNavbarCollapsed: true });
  };

  useEffect(() => {
    setIsMobileWidth(!!size.width && size.width < 1024);
    if (isMobileWidth) {
      handleCloseMenu();
    }
  }, [size.width, isMobileWidth]);

  useOnClickOutside(ref, handleCloseMenu);

  return (
    <nav className="hidden md:block w-64 bg-white shadow-md">
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-6">
          <Link to={"/wallet"}>
            <img src={LogoImg} className="w-[80%]" alt="Logo" />
          </Link>
        </h1>
        <ul className="space-y-2">
          {navbarItems.map((item) => (
            <li key={item.title}>
              {role && item.excludeForRoles?.includes(role) ? null : (
                <Link
                  to={item.link}
                  className={`flex items-center p-2 rounded-lg ${
                    currentPage === item.link
                      ? "bg-gray-200 text-blue-600"
                      : "text-gray-600 hover:bg-gray-100"
                  }`}
                >
                  {item.icon && <item.icon className="w-6 h-6 mr-3" />}
                  <span>{item.title}</span>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
    // <aside className="w-64 bg-white shadow-md">
    //   <nav className="p-4">
    //     <ul>
    //       {navbarItems.map((item) => (
    //         <li key={item.title} className="mb-2">
    //           {role && item.excludeForRoles?.includes(role) ? null : (
    //             <Link
    //               to={item.link}
    //               className={`flex items-center p-2 rounded-lg ${currentPage === item.link ? "bg-gray-200" : "hover:bg-gray-100"}`}
    //             >
    //               {item.icon && <item.icon className="w-5 h-5 mr-3" />}
    //               {item.title}
    //             </Link>
    //           )}
    //         </li>
    //       ))}
    //     </ul>
    //   </nav>
    // </aside>
    // <StyledVerticalNavbar ref={ref} className={className ?? className}>
    //   <Link to={role === RoleCodes.SELLER ? `/wallet` : "/"} className="logo">
    //     <img src={LogoImg} style={{ width: "97px" }} alt="Logo" />
    //   </Link>
    //   {navbarItems.map((item) => {
    //     return role && item.excludeForRoles?.includes(role) ? null : (
    //       <Link
    //         key={item.title}
    //         to={item.link}
    //         onClick={handleCloseMenu}
    //         className={currentPage === item.link ? "active" : ""}
    //       >
    //         {item.icon && <item.icon />}
    //         {!isNavbarCollapsed && <p>{item.title}</p>}
    //       </Link>
    //     );
    //   })}
    //   {status === UserStatus.INCOMPLETE && (
    //     <Link to="/my-profile?bank-info=true">
    //       {!isNavbarCollapsed && (
    //         <StyledCustomLink>
    //           We noticed you still haven't updated your bank info, do it here!
    //         </StyledCustomLink>
    //       )}

    //       {<LinkIcon />}
    //     </Link>
    //   )}
    //   <span>@2025, Regift Commerce LLC</span>
    // </StyledVerticalNavbar>
  );
};

export default VerticalNavbar;
