import styled from 'styled-components';

export const StyledInfo = styled.div`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
    border-color: #e4e4e7;
`;

export const StyledInfoCard = styled.div`
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    color: #09090b;
    background-color: white;
    border-width: 1px;
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
`;

export const StyledInfoCardItems = styled.div`
    padding: 1rem;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
`;

export const StyledInfoCardItemsIcon = styled.div`
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-right: 1rem;
`;

export const StyledInfoCardItemsText = styled.div`
    h3 {
        --tw-text-opacity: 1;
        color: #6b7280;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    p {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
`;

export const StyledFAQ = styled.div`
    margin-bottom: 2rem;
    width: 100%;

    h2 {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 1rem;
    }
`;

export const StyledFAQContainer = styled.div`
    width: 100%;
`;

export const StyledFAQItems = styled.div`
    border-bottom: 1px solid;
    border-color: #e4e4e7;
    h3 {
        margin: 0;
    }
    .accordion-button {
        width: 100%;
        text-align: left;
        background: none;
        border: none;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: color 0.3s ease-in-out;
    }
      
    .chevron {
        transition: transform 0.3s ease-in-out;
    }
      
    .chevron.rotated {
        transform: rotate(180deg);
    }

    .accordion-content {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out, padding 0.3s ease-out;
        padding-top: 0px;
        padding-bottom: 1rem;
      }
      
      .accordion-content.open {
        max-height: 100%;
        opacity: 1;
        padding: 10px 15px;
      }
`;

export const StyledTableContainer = styled.div`
    overflow-x: auto;

    .pricing-table {
        width: 100%;
        max-width: 800px;
        margin: 20px auto;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow-x: auto;
        padding: 20px;
        tbody tr:nth-child(even) {
            background-color: #f9f9f9;
        }
        tbody td {
            padding: 12px;
            border-bottom: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            font-size: 13px;
            white-space: nowrap;
        }
    }
      
    .pricing-header {
        background-color: #00C896;
        color: #ffffff;
        font-weight: bold;
    }
      
    .pricing-cell {
        padding: 8px 16px;
        border-bottom: 1px solid #dee2e6;
        text-align: left;
    }

    .bar {
        background-color: #00C896;
        color: white;
        text-align: right;
        padding-right: 8px;
        height: 22px;
        line-height: 22px;
        border-radius: 4px;
        font-size: 11px;
    }
`;
