import { FunctionComponent, useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import useUserStore from "@/store/user";
import { UserStatus } from "@/global/dtos/user.dto";
import useLoading from "@/hooks/useLoading";
import { startOnboarding } from "@/communication/payment";
import { toast } from "react-toastify";
import { StyledBankAccount, StyledBankAccountContainer } from "../style";
import { getBankDetails } from "@/communication/user";
import { getBankDomain } from "@/functions/getBankDomain";

const BankInfoTab: FunctionComponent = () => {
  const { status, firstName, lastName } = useUserStore();
  const { showLoading, hideLoading } = useLoading();
  const [bankLogoUrl, setBankLogoUrl] = useState<string | null>(null);
  const [bankName, setBankName] = useState<string>("");
  const [bankAccount, setBankAccount] = useState<string>("");

  const openStripeUrl = (url: string) => {
    setTimeout(() => {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 1);
  };

  const handleRedirect = () => {
    showLoading();
    startOnboarding()
      .then((res: any) => {
        if (res.data.url) {
          openStripeUrl(res.data.url);
          hideLoading();
        } else {
          toast.error("Something went wrong! Please try again.", {
            position: "bottom-center",
            autoClose: 1500,
          });
          hideLoading();
        }
      })
      .catch((err: any) => {
        console.log(err);
        hideLoading();
      });
  };

  useEffect(() => {
    if (status === UserStatus.READY || status === UserStatus.INVALID) {
      showLoading();
      getBankDetails()
        .then((res: any) => {
          if (res.data) {
            setBankAccount(res.data.bankAccount);
            setBankName(res.data.bankName);
            const bankDomain = getBankDomain(res.data?.bankName);
            if (bankDomain && bankDomain !== "") {
              setBankLogoUrl("https://logo.clearbit.com/${bankDomain}");
            }
          }
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
  }, [status]);

  return (
    <div className="space-y-6 px-4 py-6">
      {/* <StyledBankCardContainer>
        {status === UserStatus.INCOMPLETE ? (
          <>
            <StyledAddBankCard onClick={handleRedirect}>
              <AddIcon />
              <span>Update bank info</span>
            </StyledAddBankCard>
            <span>*Your bank info is incomplete. Please update it. </span>
          </>
        ) : (
          <StyledBankCard onClick={handleRedirect}>
            {bankLogoUrl ? (
              <img src={bankLogoUrl} alt={`Bank Logo`} />
            ) : (
              <span>Bank Account Added</span>
            )}
            <span>{bankName}</span>
            <span>{`****${bankAccount}`}</span>
          </StyledBankCard>
        )}
      </StyledBankCardContainer> */}
      <div>
        <StyledBankAccountContainer onClick={handleRedirect}>
          <StyledBankAccount>
            <h2>{`${firstName} ${lastName}`}</h2>
            {bankAccount && <span>{`${bankName} - ******${bankAccount}`}</span>}
            {status === UserStatus.READY ? (
              <span>✅ Fully Linked</span>
            ) : status === UserStatus.INCOMPLETE ? (
              <span>⛔ Link Bank Account</span>
            ) : (
              <span>⛔ Bank Connect Incomplete</span>
            )}
          </StyledBankAccount>
          <Button onClick={handleRedirect}>Update</Button>
        </StyledBankAccountContainer>
      </div>
    </div>
  );
};

export default BankInfoTab;
