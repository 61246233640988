import { FunctionComponent, useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useGlobalState from "@/store/global";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { ReactComponent as MenuIcon } from "@/assets/icons/navbar/menuArrow.svg";
import { ReactComponent as UserIcon } from "@/assets/icons/navbar/user.svg";
import {
  StyledHorizontalNavbar,
  StyledUserMenu,
  StyledUserDropdown,
  StyledUserHandle,
} from "./style";
import { logoutUser } from "@/communication/auth";
import useUserStore from "@/store/user";
import { getProfilePicture } from "@/communication/user";
import { FileWithPreview } from "@/types/product";
import { RoleCodes } from "@/global/roles";
import { useNavbarItems } from "../useNavItems";
type Props = {
  className?: string;
};
const HorizontalNavbar: FunctionComponent<Props> = ({ className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const navbarItems = useNavbarItems();
  const { isNavbarCollapsed } = useGlobalState();
  const { id, firstName, role, displayName } = useUserStore();
  const [profilePicture, setProfilePicture] = useState<string | undefined>(
    undefined
  );
  const currentPage = useLocation().pathname;

  const [dropdown, setDropdown] = useState(false);

  const handleCloseDropdown = (): void => {
    setDropdown(false);
  };

  const handleLogout = (): void => {
    logoutUser().then(() => {
      localStorage.removeItem("accessToken");
      window.location.href = document.location.origin;
    });
  };

  useEffect(() => {
    getProfilePicture(id)
      .then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          setProfilePicture(res.data[0].url);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useOnClickOutside(ref, handleCloseDropdown);
  return (
    <nav className="md:hidden fixed bottom-0 left-0 right-0 bg-white shadow-md">
      <ul className="flex justify-around">
        {navbarItems.map((item) =>
          role && item.excludeForRoles?.includes(role) ? null : (
            <li key={item.title} className="mb-2">
              <Link
                to={item.link}
                className={`flex flex-col items-center p-2 ${currentPage === item.link ? "text-blue-500" : "text-gray-500"}`}
              >
                {item.icon && <item.icon className="w-6 h-6" />}
                <span className="text-xs mt-1">{item.title}</span>
              </Link>
            </li>
          )
        )}
        {/* {navItems.map((item) => (
          <li key={item.name}>
            <Link
              href={item.href}
              className={`flex flex-col items-center p-2 ${pathname === item.href ? "text-blue-500" : "text-gray-500"}`}
            >
              <item.icon className="w-6 h-6" />
              <span className="text-xs mt-1">{item.name}</span>
            </Link>
          </li>
        ))} */}
      </ul>
    </nav>
    // <StyledHorizontalNavbar className={className ?? className}>
    //   <button
    //     className="menu-arrow"
    //     onClick={() =>
    //       useGlobalState.setState({ isNavbarCollapsed: !isNavbarCollapsed })
    //     }
    //   >
    //     <MenuIcon />
    //   </button>
    //   <StyledUserMenu>
    //     <button onClick={() => setDropdown(!dropdown)}>
    //       <StyledUserHandle>
    //         {profilePicture ? <img src={profilePicture}></img> : <UserIcon />}
    //         <span style={{ fontWeight: "700" }}>{displayName}</span>
    //       </StyledUserHandle>
    //     </button>
    //     <StyledUserDropdown className={dropdown ? "visible" : "hidden"}>
    //       <h4>{`Hi ${firstName}`}</h4>
    //       {role === RoleCodes.SELLER ? (
    //         <Link to="/my-profile">My profile</Link>
    //       ) : null}
    //       <Link to="/login" onClick={handleLogout}>
    //         Logout
    //       </Link>
    //     </StyledUserDropdown>
    //   </StyledUserMenu>
    // </StyledHorizontalNavbar>
  );
};

export default HorizontalNavbar;
