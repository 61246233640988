import Navbar from "@/components/navbar";
import HorizontalNavbar from "@/components/navbar/horizontal";
import VerticalNavbar from "@/components/navbar/vertical";
import initFetch from "@/functions/init";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Private: React.FunctionComponent = () => {
  // const accessToken = getAuthState();
  // console.log(accessToken);
  // const userIsLoggedIn = !!accessToken;

  useEffect(() => {
    initFetch().then();
  }, []);

  return (
    <>
      {/* <Navbar /> */}
      <div className="flex min-h-screen bg-gray-100">
        <VerticalNavbar className="fixed hidden md:block" />
        <div
          className="flex-1 pb-[60px] overflow-auto"
          onMouseUp={(e) => {
            if (e.target instanceof HTMLElement) {
              const targetedElements: HTMLElement[] = [];
              let currentElement: HTMLElement | null = e.target;

              while (currentElement !== null) {
                targetedElements.push(currentElement);
                currentElement = currentElement.parentElement;
              }
            }
          }}
        >
          <Outlet />
        </div>
        <HorizontalNavbar />
      </div>
    </>
  );
};

export default Private;
