import { CreateSellerUtm, CreateUser, UpdateSelfBankInfo, UpdateSelfUser } from '@/types/user';
import fetch from './fetch';
import routes from './routes';

export const getLoggedUser = async (): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.profile.my,
        headers: { 'Content-Type': 'application/json' },
    });
};

export const registerUser = async (data: CreateUser): Promise<any> => {
    return fetch({
        method: 'POST',
        url: routes.users.createUser,
        headers: { 'Content-Type': 'application/json' },
        data,
    });
};

export const getSellers = async (status: string): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.users.list,
        headers: { 'Content-Type': 'application/json' },
        params: { status }
    });
};

export const sellerRespond = async (id: string, approval: boolean): Promise<any> => {
    return fetch({
        method: 'POST',
        url: routes.users.editSeller(id),
        headers: { 'Content-Type': 'application/json' },
        data: { approval }
    });
};

export const updateSelf = async (data: UpdateSelfUser): Promise<any> => {
    return fetch({
        method: 'PATCH',
        url: routes.profile.my,
        headers: { 'Content-Type': 'application/json' },
        data: data,
    });
}

export const getProfilePicture = async (id: string): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.profile.picture(id),
        headers: { 'Content-Type': 'application/json' },
    })
};

export const uploadProfilePicture = async (formData: FormData): Promise<any> => {
    return fetch({
        method: 'POST',
        url: routes.profile.profilePicture,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    });
}

export const deleteProfilePicture = async (pictureId: string): Promise<any> => {
    return fetch({
        method: 'DELETE',
        url: routes.profile.profilePicture,
        data: { pictureId }
    });
}

export const getBankDetails = async (): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.profile.account,
        headers: { 'Content-Type': 'application/json' },
    })
};

export const updateBankDetails = async (agencyName?: string, bankInfo?: UpdateSelfBankInfo): Promise<any> => {
    return fetch({
        method: 'PATCH',
        url: routes.profile.account,
        headers: { 'Content-Type': 'application/json' },
        data: { agencyName, bankInfo },
    });
};

export const createSeller = async (data: CreateUser) => {
    return fetch({
        method: 'POST',
        url: routes.users.createSeller,
        headers: { 'Content-Type': 'application/json' },
        data,
    });
};

export const notifySeller = async (id: string) => {
    return fetch({
        method: 'POST',
        url: routes.users.notifySeller(id),
        headers: { 'Content-Type': 'application/json' },
    });
};

export const getSellersAnalytics = async (page: number, search?: string, seller?: string, commissionType?: string, sortBy?: string): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.users.sellerAnalytics,
        headers: { 'Content-Type': 'application/json' },
        params: { page, search, seller, commissionType, sortBy }
    });
};

export const getMyUtmDetails = async (): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.users.myUtmData,
        headers: { 'Content-Type': 'application/json' },
    })
};

export const getUtmData = async (id: string): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.users.utmData(id),
        headers: { 'Content-Type': 'application/json' },
    })
};

export const createUtmData = async (id: string, data: CreateSellerUtm): Promise<any> => {
    return fetch({
        method: 'POST',
        url: routes.users.utmData(id),
        headers: { 'Content-Type': 'application/json' },
        data,
    })
};

export const updateUtmData = async (id: string, data: CreateSellerUtm): Promise<any> => {
    return fetch({
        method: 'PATCH',
        url: routes.users.utmData(id),
        headers: { 'Content-Type': 'application/json' },
        data,
    })
};