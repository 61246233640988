import { FunctionComponent, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  StyledAuthWrapper,
  StyledForm,
  StyledAuthContent,
  StyledImage,
} from "@/pages/login/style";
import InfluencerImg from "@/assets/images/influencer.webp";
import { resetPassword } from "@/communication/auth";
import { returnToLogin } from "@/functions/auth";
import { StyledErrorMessage } from "./style";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

type FormValues = {
  password: string;
  confirmPassword: string;
};

const CreatePassword: FunctionComponent = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    password: "",
    confirmPassword: "",
  });
  // const passwordErrorMessage = useMinLength(formValues.password, 8);
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const windowUrl = window.location.search;
  const urlParams = new URLSearchParams(windowUrl);
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    });
    setIsDirty(true);
  };

  useEffect(() => {
    const token = urlParams.get("token") ?? "";
    if (!token) returnToLogin("resetPassword");
  }, []);

  useEffect(() => {
    setPasswordMismatch(false);
  }, [formValues.confirmPassword, formValues.password]);

  const handleResetPassword = (): void => {
    if (formValues.password !== formValues.confirmPassword) {
      setPasswordMismatch(true);
    } else {
      const token = urlParams.get("token") ?? "";
      resetPassword(token, formValues.password)
        .then(() => {
          toast.success("Password Created!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          setIsDirty(false);
        })
        .catch((error: any) => {
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
      navigate("/login");
      return;
    }
  };

  useEffect(() => {
    const token = urlParams.get("token") ?? "";
    if (!token) returnToLogin("resetPassword");
  }, []);

  return (
    <StyledAuthWrapper>
      <StyledAuthContent>
        <StyledImage>
          <img src={InfluencerImg} alt="influencer" />
        </StyledImage>
        <StyledForm>
          <h2>Create password</h2>
          <div>
            <Label htmlFor="email">Password</Label>
            <Input
              id="password"
              type="password"
              value={formValues.password}
              onChange={handleInputChange}
              className="mt-1"
            />
          </div>
          <div>
            <Label htmlFor="email">Confirm password</Label>
            <Input
              id="confirmPassword"
              type="password"
              value={formValues.confirmPassword}
              onChange={handleInputChange}
              className="mt-1"
            />
          </div>
          <div className="pt-6 border-t">
            <Button onClick={handleResetPassword} disabled={!isDirty}>
              Create password
            </Button>
            {passwordMismatch && (
              <StyledErrorMessage>Passwords do not match!</StyledErrorMessage>
            )}
          </div>
        </StyledForm>
      </StyledAuthContent>
    </StyledAuthWrapper>
  );
};
export default CreatePassword;
