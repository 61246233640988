import { getProductPictures } from "@/communication/product";
import { ProductDto } from "@/global/dtos/product.dto";
import {
  FileWithPreview,
  Product,
  ProductCategory,
  ProductCondition,
} from "@/types/product";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { FunctionComponent } from "react";
import useLoading from "@/hooks/useLoading";
import PlaceholderIcon from "@/assets/icons/image-placeholder.svg";
import { getProfilePicture } from "@/communication/user";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";

// interface Props {
//   product: ProductDto;
// }

interface ProductCardProps {
  product: ProductDto;
  onClick: (product: Product) => void;
}

const ProductCard: FunctionComponent<ProductCardProps> = ({
  product,
  onClick,
}) => {
  const [productPictures, setProductPictures] = useState<FileWithPreview[]>([]);
  const { showLoading, hideLoading } = useLoading();
  const [sellerPicture, setSellerPicture] = useState<string | undefined>(
    undefined
  );

  const memoizedProductImage = useMemo(
    () => productPictures[0]?.preview ?? PlaceholderIcon,
    [productPictures]
  );

  const memoizedSellerImage = useMemo(() => sellerPicture, [sellerPicture]);

  useEffect(() => {
    if (product.id) {
      showLoading();
      getProductPictures(product.id).then((res: any) => {
        const pictures = res.data.map((picture: any) => {
          return { id: picture.id, preview: picture.url };
        });
        setProductPictures(pictures);
        hideLoading();
      });
      getProfilePicture(product.sellerId)
        .then((res: any) => {
          if (res && res.data && res.data.length > 0) {
            setSellerPicture(res.data[0].url);
          }
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
  }, []);
  return (
    <Card
      className="cursor-pointer hover:shadow-lg transition-shadow duration-200"
      onClick={() =>
        onClick({
          id: product.id,
          brand: product.brand.name,
          name: product.name,
          description: product.description,
          price: product.price,
          isPublic: product.isPublic,
          selfListed: product.selfListed,
          discount: product.discount,
          isDiscounted: product.isDiscounted,
          category: product.category as ProductCategory,
          condition: product.condition as ProductCondition,
          sellerId: product.seller.id,
          expectedCommission: product.expectedCommission,
          compareAtPrice: product.compareAtPrice,
          quantity: product.quantity,
        })
      }
    >
      <CardContent className="p-3 sm:p-4 bg-white">
        <div className="relative">
          <img
            src={memoizedProductImage}
            alt={product.name}
            width={300}
            height={300}
            className="w-full aspect-square object-cover mb-2 sm:mb-4 rounded-lg"
            loading="lazy"
          />
          <Badge
            className={`absolute top-2 right-2 text-xs sm:text-sm ${product.sold ? "bg-green-500" : "bg-blue-500"}`}
          >
            {product.withdrawn ? "Withdrawn" : product.sold ? "Sold" : "Listed"}
          </Badge>
        </div>
        <h3 className="text-sm sm:text-lg font-semibold mb-1 sm:mb-2 truncate">
          {product.name}
        </h3>
        <div className="mb-1 sm:mb-2">
          <span className="text-sm sm:text-xl font-bold">${product.price}</span>
          <div className="text-xs sm:text-sm text-gray-600">
            Comm: ${product.expectedCommission}
          </div>
        </div>
        <div className="flex items-center">
          {memoizedSellerImage ? (
            <img
              src={memoizedSellerImage}
              alt="sellerPic"
              width={24}
              height={24}
              className="rounded-full mr-2 w-4 h-4 sm:w-6 sm:h-6"
              loading="lazy"
            ></img>
          ) : null}
          <span className="text-xs sm:text-sm text-gray-600 truncate">
            {`${product.seller.firstName} ${product.seller.lastName}`}
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
