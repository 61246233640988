import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createProduct,
  getAllProducts,
  updateProduct,
} from "@/communication/product";
import PageTitle from "@/components/pageTitle";
import { ProductDto } from "@/global/dtos/product.dto";
import ProductCard from "./productCard";
import { Button } from "@/components/ui/button";
import useLoading from "@/hooks/useLoading";
import { Product } from "@/types/product";
import useUserStore from "@/store/user";
import { RoleCodes } from "@/global/roles";
import { getSellers } from "@/communication/user";
import { UserDto } from "@/global/dtos/user.dto";
import ProductsControls, { SellerFilterOption } from "./productControls";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import ProductListView from "./productListView";
import { MyPagination } from "./pagination";
import EditProductModal from "./editProduct";

const Products: FunctionComponent = () => {
  const [products, setProducts] = useState<ProductDto[] | null>(null);
  const [listView, setListView] = useState(false);
  const { showLoading, hideLoading } = useLoading();
  const [searchValue, setSearchValue] = useState("");
  const [sellers, setSellers] = useState<SellerFilterOption[]>([]);
  const [filterCategory, setFilterCategory] = useState("");
  const [filterSeller, setFilterSeller] = useState("");
  const [order, setOrder] = useState("dateAsc");
  const { role } = useUserStore();
  const [usersList, setUsersList] = useState<UserDto[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editProduct, setEditProduct] = useState<Product | undefined>(
    undefined
  );

  const fetchUsers = async () => {
    try {
      // Perform both API calls in parallel
      const [
        readyResponse,
        invalidResponse,
        incompleteResponse,
        createdResponse,
      ] = await Promise.all([
        getSellers("READY"),
        getSellers("INVALID"),
        getSellers("INCOMPLETE"),
        getSellers("CREATED"),
      ]);

      // Extract the data from the responses
      const readyUserList: UserDto[] = readyResponse.data || [];
      const invalidUserList: UserDto[] = invalidResponse.data || [];
      const incompleteUserList: UserDto[] = incompleteResponse.data || [];
      const createdUserList: UserDto[] = createdResponse.data || [];

      // Merge the arrays and set the state
      setUsersList &&
        setUsersList([
          ...readyUserList,
          ...invalidUserList,
          ...incompleteUserList,
          ...createdUserList,
        ]);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    if (role === RoleCodes.ADMIN) {
      fetchUsers();
    }
  }, [role]);

  useEffect(() => {
    if (usersList && usersList.length > 0) {
      const sellersOptions: SellerFilterOption[] = usersList.map((user) => ({
        value: user?.id,
        label:
          user?.firstName && user?.lastName
            ? `${user.firstName} ${user.lastName}`
            : (user?.firstName ?? ""),
      }));

      setSellers(sellersOptions);
    }
  }, [usersList]);

  useEffect(() => {
    showLoading();
    getAllProducts(currentPage)
      .then((res: any) => {
        setProducts(res.data.products);
        setTotalPages(res.data.totalPages);
        setCurrentPage(res.data.currentPage);
        //setFilteredProducts(res.data);
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  }, []);

  useEffect(() => {
    showLoading();
    getAllProducts(
      currentPage,
      searchValue,
      filterCategory,
      filterSeller,
      order
    )
      .then((res: any) => {
        setProducts(res.data.products);
        setTotalPages(res.data.totalPages);
        setCurrentPage(res.data.currentPage);
        //setFilteredProducts(res.data);
        hideLoading();
      })
      .catch((error) => {
        console.log(error);
        hideLoading();
      });
  }, [searchValue, filterCategory, filterSeller, currentPage, order]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page + 1);
  };

  const handleSearch = (term: string) => {
    setSearchValue(term);
  };

  const handleFilterCategory = (category: string) => {
    setFilterCategory(category === "ALL" ? "" : category);
  };

  const handleFilterSeller = (seller: string) => {
    setFilterSeller(seller === "ALL" ? "" : seller);
  };

  const handleSort = (method: string) => {
    setOrder(method);
    // setSortMethod(method);
  };

  const handleProductClick = (product: Product) => {
    setIsEditModalOpen(true);
    setEditProduct(product);
  };

  const handleAddProduct = () => {
    setIsAddModalOpen(true);
  };

  const handleSaveNewProduct = (newProduct: Product) => {
    showLoading();
    if (newProduct) {
      const { expectedCommission, ...rest } = newProduct;
      createProduct(rest)
        .then(async () => {
          getAllProducts(currentPage).then((res: any) => {
            setProducts(res.data.products);
          });
          hideLoading();
          setIsAddModalOpen(false);
          toast.success("Product added!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    } else {
      hideLoading();
      toast.error("Something went wrong!", {
        position: "bottom-center",
        autoClose: 1500,
      });
    }
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSaveProduct = (updatedProduct: Product) => {
    if (updatedProduct.id) {
      showLoading();
      const { id, ...rest } = updatedProduct;
      updateProduct(updatedProduct.id, rest)
        .then(async (res: any) => {
          getAllProducts(currentPage).then((res: any) => {
            setProducts(res.data.products);
          });
          hideLoading();
          toast.success("Product info updated!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    }
  };

  return (
    <>
      <PageTitle title="My Products" />
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
          <ProductsControls
            onSearch={handleSearch}
            onFilterCategory={handleFilterCategory}
            onFilterSeller={handleFilterSeller}
            onSort={handleSort}
            sellers={sellers ?? undefined}
          />
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Switch
                id="list-view"
                checked={listView}
                onCheckedChange={setListView}
              />
              <Label htmlFor="list-view">List View</Label>
            </div>
            {role === RoleCodes.ADMIN && (
              <Button onClick={handleAddProduct}>Add Product</Button>
            )}
          </div>
        </div>
        {listView ? (
          <>
            <div>
              <ProductListView
                products={products ?? []}
                onProductClick={() => {}}
              />
            </div>
            <MyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6">
              {products?.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  onClick={
                    role === RoleCodes.ADMIN ? handleProductClick : () => {}
                  }
                />
              ))}
            </div>
            <MyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </div>
      {isAddModalOpen && (
        <EditProductModal
          isOpen={isAddModalOpen}
          sellers={usersList || undefined}
          onClose={() => setIsAddModalOpen(false)}
          onSave={handleSaveNewProduct}
        />
      )}
      {isEditModalOpen && (
        <EditProductModal
          product={editProduct}
          sellers={usersList || undefined}
          isOpen={isEditModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveProduct}
        />
      )}
    </>
  );
};
export default Products;
