import { FunctionComponent, ReactElement } from "react";
import { Settings } from "lucide-react";
import { logoutUser } from "@/communication/auth";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import useUserStore from "@/store/user";
// import { styled } from "styled-components";

type Props = {
  title: string;
  icon?: ReactElement;
};

const handleLogout = (): void => {
  logoutUser().then(() => {
    localStorage.removeItem("accessToken");
    window.location.href = document.location.origin;
  });
};

const PageTitle: FunctionComponent<Props> = ({ title, icon }) => {
  const { firstName, lastName } = useUserStore();
  return (
    <div className="sticky top-0 bg-gray-100 py-4 px-4 md:px-8 mb-4 border-b border-gray-200 flex justify-between items-center">
      <h1 className="text-2xl font-bold md:text-3xl">{title}</h1>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Settings className="w-5 h-5 cursor-pointer " />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="cursor-pointer bg-white p-3">
          <DropdownMenuItem>Hi {`${firstName} ${lastName}`}</DropdownMenuItem>
          <DropdownMenuItem onSelect={() => handleLogout()}>
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

// const StyledPageTitle = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 2.19rem;
//   svg {
//     margin-right: 0.75rem;
//   }
//   h1 {
//     font-weight: 700;
//   }
// `;
export default PageTitle;
