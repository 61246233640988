import { Route, Routes } from "react-router-dom";
import Login from "@/pages/login";
import MyProfile from "@/pages/myProfile";
import Applicants from "@/pages/applicants";
import ForgotPassword from "@/pages/forgotPassword";
import ResetPassword from "@/pages/resetPassword";
import CreatePassword from "@/pages/createPassword";
import Register from "@/pages/register";
import Public from "./public";
import Private from "./private";
import Products from "@/pages/products";
import ProductDetails from "@/pages/products/details";
import AgencyList from "@/pages/agencies";
import AgencyDetails from "@/pages/agencies/details";
import CreatorList from "@/pages/creators";
import Wallet from "@/pages/wallet";

const content: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route element={<Public />}>
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/confirm" element={<CreatePassword />} />
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<Private />}>
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:productId" element={<ProductDetails />} />
        <Route path="/agencies" element={<AgencyList />} />
        <Route path="/agencies/:agencyId" element={<AgencyDetails />} />
        <Route path="/creators" element={<CreatorList />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/" element={<Applicants />} />
      </Route>
    </Routes>
  );
};

export default content;
