import { FunctionComponent, useState } from "react";
import InputField from "@/components/inputs/inputField";
import {
  StyledAuthContent,
  StyledAuthWrapper,
  StyledForm,
  StyledImage,
  StyledWarrning,
} from "./style";
import { useTouchField } from "@/hooks/useTouchField";
import useIsEmail from "@/hooks/validation/useIsEmail";
import { ReactComponent as PersonIcon } from "@/assets/icons/input/person.svg";
import { ReactComponent as KeyIcon } from "@/assets/icons/input/key.svg";
import useMinLength from "@/hooks/validation/useMinLength";
import Button from "@/components/buttons";
import InfluencerImg from "@/assets/images/influencer.webp";
import { registerUser } from "@/communication/user";
import { StyledBanner } from "../login/style";
import { toast } from "react-toastify";
import { UserCommissionType } from "@/global/dtos/user.dto";

type FormValues = {
  firstName: string;
  lastName: string;
  instaHandle: string;
  tiktokHandle: string;
  email: string;
  password: string;
  displayName: string;
  commissionType: UserCommissionType;
};

const Register: FunctionComponent = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    firstName: "",
    lastName: "",
    instaHandle: "",
    tiktokHandle: "",
    email: "",
    password: "",
    displayName: "",
    commissionType: UserCommissionType.TIERED_V1,
  });

  const emailErrorMessage = useIsEmail(formValues.email);
  const passwordErrorMessage = useMinLength(formValues.password, 8);
  const isFormValid = !(
    formValues.firstName === "" ||
    formValues.lastName === "" ||
    (formValues.instaHandle === "" && formValues.tiktokHandle === "") ||
    formValues.displayName === "" ||
    emailErrorMessage ||
    passwordErrorMessage
  );
  const [submitted, setSubmitted] = useState(false);

  const { isFieldTouched, handleFieldTouch } = useTouchField<FormValues>();

  const handleChange = (fieldName: keyof FormValues, value): void => {
    handleFieldTouch(fieldName);
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    setSubmitted(false);
  };
  const handleRegister = (): void => {
    registerUser(formValues)
      .then(() => {
        toast.success("Application submitted!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        setSubmitted(true);
      })
      .catch((error: any) => {
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
  };

  return (
    <StyledAuthWrapper>
      <StyledAuthContent>
        <StyledImage>
          <StyledBanner>Let the Journey Begin</StyledBanner>
          <img src={InfluencerImg} alt="Influencer image" />
        </StyledImage>
        <StyledForm>
          <h2>Register</h2>
          <InputField
            type="text"
            label="First name"
            value={formValues.firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
            placeholder="First Name"
            icon={<PersonIcon />}
          />
          <InputField
            type="text"
            label="Last name"
            value={formValues.lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
            placeholder="Last name"
            icon={<PersonIcon />}
          />
          <InputField
            type="text"
            label="instagram handle"
            value={formValues.instaHandle}
            onChange={(e) => handleChange("instaHandle", e.target.value)}
            placeholder="Instagram handle"
            icon={<PersonIcon />}
          />
          <InputField
            type="text"
            label="TikTok handle"
            value={formValues.tiktokHandle}
            onChange={(e) => handleChange("tiktokHandle", e.target.value)}
            placeholder="TikTok handle"
            icon={<PersonIcon />}
          />
          <InputField
            type="text"
            label="Display name"
            value={formValues.displayName}
            onChange={(e) => handleChange("displayName", e.target.value)}
            placeholder="Display name"
            icon={<PersonIcon />}
          />
          <InputField
            type="text"
            label="Email"
            value={formValues.email}
            errorMessage={isFieldTouched("email") ? emailErrorMessage : ""}
            onChange={(e) =>
              handleChange("email", e.target.value.toLowerCase())
            }
            placeholder="Email"
            icon={<PersonIcon />}
          />
          <InputField
            type="password"
            label="Password"
            value={formValues.password}
            errorMessage={
              isFieldTouched("password") ? passwordErrorMessage : ""
            }
            onChange={(e) => handleChange("password", e.target.value)}
            placeholder="Password"
            icon={<KeyIcon />}
          />
          {submitted ? (
            <StyledWarrning>
              Your application is being processed. You will be notified by an
              email when the process is done. Thank you for your patience.
            </StyledWarrning>
          ) : (
            <Button
              style={{ marginTop: "20px" }}
              disabled={!isFormValid}
              width="100%"
              onClick={handleRegister}
            >
              Register
            </Button>
          )}
        </StyledForm>
      </StyledAuthContent>
    </StyledAuthWrapper>
  );
};

export default Register;
