import { FunctionComponent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "@/components/inputs/inputField";
import {
  StyledAuthContent,
  StyledAuthWrapper,
  StyledBanner,
  StyledForgotLink,
  StyledForm,
  StyledImage,
  StyledLogo,
  StyledRegister,
  StyledWarrning,
} from "./style";
import { useTouchField } from "@/hooks/useTouchField";
import useIsEmail from "@/hooks/validation/useIsEmail";
import useMinLength from "@/hooks/validation/useMinLength";
import InfluencerImg from "@/assets/images/influencer.webp";
import { loginUser } from "@/communication/auth";
import LogoImg from "@/assets/images/logo.png";
import useUserStore from "@/store/user";
import { RoleCodes } from "@/global/roles";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

type FormValues = {
  email: string;
  password: string;
};

const Login: FunctionComponent = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    password: "",
  });

  const { role } = useUserStore();
  const navigate = useNavigate();
  const emailErrorMessage = useIsEmail(formValues.email);
  const passwordErrorMessage = useMinLength(formValues.password, 8);
  const isFormValid = !(emailErrorMessage || passwordErrorMessage);
  const [submitted, setSubmitted] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleLogin = (): void => {
    loginUser(formValues)
      .then(async (res: any) => {
        localStorage.setItem("accessToken", res?.data?.accessToken);
        if (role === RoleCodes.ADMIN) {
          navigate("/products");
        } else {
          navigate("/wallet");
        }
      })
      .catch((error: any) => {
        if (error.response.data.code === 1001) {
          toast.error("Invalid credentials!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        } else if (JSON.parse(error.request.response).code === 1006) {
          toast.error("Invalid email!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        } else if (JSON.parse(error.request.response).code === 3006) {
          setSubmitted(true);
        } else {
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        }
        console.log(error);
      });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.id]:
        e.target.id === "email" ? e.target.value.toLowerCase() : e.target.value,
    });
    setIsDirty(true);
  };

  return (
    <StyledAuthWrapper>
      <StyledAuthContent>
        <StyledImage>
          <StyledBanner>Let the Journey Begin!</StyledBanner>
          <img src={InfluencerImg} alt="Influencer image" />
        </StyledImage>
        <StyledForm>
          <StyledLogo src={LogoImg} alt="Logo" />
          <h2>Login</h2>
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={formValues.email}
              onChange={handleInputChange}
              className="mt-1"
            />
          </div>
          <div>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={formValues.password}
              onChange={handleInputChange}
              className="mt-1"
            />
          </div>
          <StyledForgotLink>
            <Link to="/forgot-password">Forgot password?</Link>
          </StyledForgotLink>
          {submitted ? (
            <StyledWarrning>
              Your application is being processed. You will be notified by an
              email when the process is done. Thank you for your patience.
            </StyledWarrning>
          ) : (
            <StyledRegister>
              <div>
                <Button disabled={!isFormValid} onClick={handleLogin}>
                  Login
                </Button>
              </div>
            </StyledRegister>
          )}
        </StyledForm>
      </StyledAuthContent>
    </StyledAuthWrapper>
  );
};

export default Login;
