import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { ProductDto } from "@/global/dtos/product.dto";

interface ProductListViewProps {
  products: ProductDto[];
  onProductClick: (product: ProductDto) => void;
}

export default function ProductListView({
  products,
  onProductClick,
}: ProductListViewProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Price</TableHead>
          <TableHead>Category</TableHead>
          <TableHead>Expected Commission</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Seller</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {products.map((product) => (
          <TableRow
            key={product.id}
            onClick={() => onProductClick(product)}
            className="cursor-pointer hover:bg-gray-100"
          >
            <TableCell>{product.name}</TableCell>
            <TableCell>${product.price}</TableCell>
            <TableCell>{product.category}</TableCell>
            <TableCell>${product.expectedCommission}</TableCell>
            <TableCell>
              <Badge className={product.sold ? "bg-green-500" : "bg-blue-500"}>
                {product.sold ? "Sold" : "Listed"}
              </Badge>
            </TableCell>
            <TableCell>{`${product.seller.firstName} ${product.seller.lastName}`}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
