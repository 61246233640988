import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "@/components/ui/select";
import {
  FileWithPreview,
  Product,
  ProductCategory,
  ProductCondition,
} from "@/types/product";
import { Textarea } from "@/components/ui/textarea";
import { getDiscount } from "@/functions/calculateDiscount";
import {
  deleteProduct,
  deleteProductPicture,
  getProductPictures,
  sendUGCProduct,
  uploadProductPictures,
  withdrawProduct,
} from "@/communication/product";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { UserDto } from "@/global/dtos/user.dto";
import useUserStore from "@/store/user";
import { RoleCodes } from "@/global/roles";
import { SelectOptionType } from "@/components/select";
import PictureUpload from "@/components/pictureUpload";
import useLoading from "@/hooks/useLoading";

interface EditProductModalProps {
  product?: Product;
  sellers?: UserDto[];
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedProduct: Product) => void;
}

export default function ProductModal({
  product,
  sellers,
  isOpen,
  onClose,
  onSave,
}: EditProductModalProps) {
  const [editedProduct, setEditedProduct] = useState<Product>(
    product || {
      id: undefined,
      brand: undefined,
      name: undefined,
      description: undefined,
      price: undefined,
      isPublic: false,
      selfListed: false,
      discount: undefined,
      isDiscounted: undefined,
      category: ProductCategory.CLOTHING,
      condition: ProductCondition.NEVER,
      sellerId: undefined,
      expectedCommission: undefined,
      compareAtPrice: undefined,
      quantity: undefined,
    }
  );

  const { role } = useUserStore();

  const categories = Object.keys(ProductCategory).map((category) => {
    return { value: category, label: category };
  });

  const conditions = Object.keys(ProductCondition).map((condition) => {
    return { value: condition, label: condition };
  });

  const [sellersOptions, setSellersOptions] = useState<SelectOptionType[]>([]);
  const [productPictures, setProductPictures] = useState<FileWithPreview[]>([]);
  const [newPictures, setNewPictures] = useState<FileWithPreview[]>([]);
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    if (sellers && sellersOptions.length === 0) {
      setSellersOptions(
        sellers.map((user) => ({
          value: user?.id,
          label:
            user?.firstName && user?.lastName
              ? `${user.firstName} ${user.lastName}`
              : (user?.firstName ?? ""),
        }))
      );
    }
  }, []);

  useEffect(() => {
    if (editedProduct.id) {
      showLoading();
      getProductPictures(editedProduct.id).then((res: any) => {
        const pictures = res.data.map((picture: any) => {
          return { id: picture.id, preview: picture.url };
        });
        setProductPictures(pictures);
        hideLoading();
      });
    }
  }, []);

  useEffect(() => {
    setEditedProduct((prev) => ({
      ...prev,
      discount: getDiscount(editedProduct.category, editedProduct.condition),
    }));
  }, [editedProduct.category, editedProduct.condition]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEditedProduct((prev) => ({
      ...prev,
      [name]:
        name === "price" || name === "expectedCommission"
          ? Number.parseFloat(value)
          : name === "brand"
            ? value.toUpperCase()
            : name === "quantity"
              ? Number.parseInt(value)
              : value,
    }));
  };

  const handleSave = () => {
    const updatedProduct = { ...editedProduct };
    onSave(updatedProduct);
    onClose();
  };

  const handleUGCSend = () => {
    if (editedProduct.id) {
      showLoading();
      sendUGCProduct(editedProduct.id)
        .then(() => {
          hideLoading();
          toast.success("Product marked as UCG!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        })
        .catch((err) => {
          toast.error("Product marked as UCG failed!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(err);
        });
    }
  };

  const savePictures = () => {
    const formData = new FormData();
    newPictures.forEach((file) => {
      formData.append("files", file);
    });
    if (editedProduct.id) {
      showLoading();
      uploadProductPictures(editedProduct.id, formData)
        .then((res: any) => {
          hideLoading();
          toast.success("Product pictures uploaded!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        })
        .catch((err) => {
          toast.error("Product pictures upload failed!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(err);
        });
    }
  };

  const handleRemoveFile = (file: FileWithPreview) => {
    setProductPictures((prevFiles) => prevFiles.filter((f) => f !== file));
    URL.revokeObjectURL(file.preview);
    if (file.id) {
      deleteProductPicture(file.id)
        .then((res: any) => {})
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files).map((file) => {
        const preview = URL.createObjectURL(file);
        return Object.assign(file, { preview });
      });
      setNewPictures((prevFiles) => [...prevFiles, ...filesArray]);
      setProductPictures((prevFiles) => [...prevFiles, ...filesArray]);
      savePictures();
    }
  };

  const handleDelete = (): void => {
    if (editedProduct.id) {
      showLoading();
      deleteProduct(editedProduct.id)
        .then(async (res: any) => {
          toast.success("Product successfully deleted!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          hideLoading();
          onClose();
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    }
  };

  const handleWithdraw = () => {
    if (editedProduct.id) {
      showLoading();
      withdrawProduct(editedProduct.id)
        .then(() => {
          hideLoading();
          toast.success("Product withdrawn!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        })
        .catch((err) => {
          toast.error("Product withdrawal failed!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(err);
        });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-white max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>
            {product ? "Edit Product" : "Add New Product"}
          </DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="sm:max-w-[400px] gap-4">
            <PictureUpload
              onRemove={handleRemoveFile}
              onFileChange={handleFileChange}
              pictureList={productPictures}
              multiple={true}
              showActions={true}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
              id="name"
              name="name"
              value={editedProduct.name}
              onChange={handleChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="status" className="text-right">
              Brand
            </Label>
            <Input
              id="brand"
              name="brand"
              value={editedProduct.brand}
              onChange={handleChange}
              className="col-span-3"
            />
          </div>
          {role === RoleCodes.ADMIN && (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="seller" className="text-right">
                Seller
              </Label>
              <Select
                onValueChange={(e) => {
                  setEditedProduct((prev) => ({ ...prev, sellerId: e }));
                }}
              >
                <SelectTrigger className="w-[280px]">
                  <SelectValue placeholder="Select a seller">
                    {sellersOptions.find(
                      (seller) => seller.value === editedProduct.sellerId
                    )?.label || "Select a seller"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-white">
                  <SelectGroup>
                    <SelectLabel>Sellers</SelectLabel>
                    {sellersOptions.map((seller) => (
                      <SelectItem value={seller.value}>
                        {seller.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          )}
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="status" className="text-right">
              Description
            </Label>
            <Textarea
              id="description"
              name="description"
              value={editedProduct.description}
              onChange={handleChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="price" className="text-right">
              Price
            </Label>
            <Input
              id="price"
              name="price"
              type="number"
              value={editedProduct.price}
              onChange={handleChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="price" className="text-right">
              Compare at price
            </Label>
            <Input
              id="compareAtPrice"
              name="compareAtPrice"
              type="number"
              value={editedProduct.compareAtPrice}
              onChange={handleChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="expectedCommission" className="text-right">
              Expected Commission
            </Label>
            <Input
              id="expectedCommission"
              name="expectedCommission"
              type="number"
              value={editedProduct.expectedCommission}
              onChange={handleChange}
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="category" className="text-right">
              Category
            </Label>
            <Select
              value={editedProduct.category}
              onValueChange={(e: ProductCategory) => {
                setEditedProduct((prev) => ({ ...prev, category: e }));
              }}
            >
              <SelectTrigger className="w-[280px]">
                <SelectValue placeholder="Select a category" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectGroup>
                  <SelectLabel>Category</SelectLabel>
                  {categories.map((category) => (
                    <SelectItem value={category.value}>
                      {category.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="category" className="text-right">
              Condition
            </Label>
            <Select
              value={editedProduct.condition}
              onValueChange={(e: ProductCondition) => {
                setEditedProduct((prev) => ({ ...prev, condition: e }));
              }}
            >
              <SelectTrigger className="w-[280px]">
                <SelectValue placeholder="Select a condition" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectGroup>
                  <SelectLabel>Condition</SelectLabel>
                  {conditions.map((condition) => (
                    <SelectItem value={condition.value}>
                      {condition.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="expectedCommission" className="text-right">
              Discount
            </Label>
            <Input
              id="discount"
              name="discount"
              type="number"
              value={editedProduct.discount}
              placeholder={getDiscount(
                product?.category ?? ProductCategory.CLOTHING,
                product?.condition ?? ProductCondition.NEVER
              ).toString()}
              onChange={handleChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="expectedCommission" className="text-right">
              Quantity
            </Label>
            <Input
              id="quantity"
              name="quantity"
              type="number"
              value={editedProduct.quantity}
              onChange={handleChange}
              className="col-span-3"
            />
          </div>
        </div>
        <DialogFooter className="flex flex-wrap gap-2">
          {product && (
            <div className="w-full flex justify-center gap-2">
              {product && (
                <Button
                  type="submit"
                  onClick={handleUGCSend}
                  className="flex-1"
                >
                  Sent to UGC
                </Button>
              )}
              {product && (
                <Button
                  type="submit"
                  onClick={handleWithdraw}
                  className="flex-1"
                >
                  Withdraw Product
                </Button>
              )}
            </div>
          )}
          <div className="w-full flex justify-center gap-2">
            {product && (
              <Button type="submit" onClick={handleDelete} className="flex-1">
                Delete product
              </Button>
            )}
            <Button type="submit" onClick={handleSave} className="flex-1">
              {product ? "Save changes" : "Add Product"}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
