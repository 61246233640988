import { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Copy, Camera } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import useUserStore from "@/store/user";
import useLoading from "@/hooks/useLoading";
import {
  getProfilePicture,
  updateSelf,
  uploadProfilePicture,
} from "@/communication/user";
import { FileUpload } from "@/components/ui/fileUpload";
import { generateToken } from "@/communication/auth";

const ProfileTab: FunctionComponent = () => {
  const { id, displayName, firstName, lastName, email, phone } = useUserStore();
  const { showLoading, hideLoading } = useLoading();
  type UserInfoFormValues = {
    displayName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
  };
  const [formData, setFormData] = useState<UserInfoFormValues>({
    displayName: displayName ?? "",
    firstName: firstName ?? "",
    lastName: lastName ?? "",
    email: email ?? "",
    phone: phone ?? "",
  });
  const [isDirty, setIsDirty] = useState(false);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  useEffect(() => {
    setFormData({
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      phone: phone ?? "",
      displayName: displayName ?? "",
    });
  }, [firstName, lastName, email, phone, displayName]);

  useEffect(() => {
    showLoading();
    getProfilePicture(id)
      .then((res: any) => {
        const pictures = res.data.map((picture: any) => {
          return { id: picture.id, preview: picture.url };
        });
        setProfilePicture(pictures[0].preview);
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    showLoading();
    getProfilePicture(id)
      .then((res: any) => {
        const pictures = res.data.map((picture: any) => {
          return { id: picture.id, preview: picture.url };
        });
        setProfilePicture(pictures[0].preview);
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        console.log(error);
      });
  }, [profilePicture]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.id]:
        e.target.id === "email" ? e.target.value.toLowerCase() : e.target.value,
    });
    setIsDirty(true);
  };

  const handleSave = () => {
    showLoading();
    updateSelf(formData)
      .then((res: any) => {
        setFormData({
          firstName: res.data.firstName ?? "",
          lastName: res.data.lastName ?? "",
          email: res.data.email ?? "",
          phone: res.data.phone ?? "",
          displayName: res.data.displayName ?? "",
        });
        hideLoading();
        toast.success("Profile updated!", {
          position: "bottom-center",
          autoClose: 1500,
        });
      })
      .catch((error: any) => {
        hideLoading();
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
    setIsDirty(false);
  };

  const copyToCLipboard = () => {
    navigator.clipboard.writeText(
      `https://tryregift.com/${firstName}-${lastName}`
    );
    toast.success("Text copied!", {
      position: "bottom-center",
      autoClose: 1500,
    });
  };

  const handlePictureChange = (file: File) => {
    showLoading();
    const formData = new FormData();
    formData.append("files", file);
    uploadProfilePicture(formData)
      .then((res: any) => {
        hideLoading();
        setProfilePicture(res.data.url);
        toast.success("Profile picture updated!", {
          position: "bottom-center",
          autoClose: 1500,
        });
      })
      .catch((err) => {
        hideLoading();
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(err);
      });
  };

  const handleChangePassword = () => {
    if (email) {
      showLoading();
      generateToken(email)
        .then(() => {
          hideLoading();
          toast.success("Email sent! Check your inbox.", {
            position: "bottom-center",
            autoClose: 1500,
          });
        })
        .catch((error) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        });
    } else {
      toast.error("You must add an email before updating password!", {
        position: "bottom-center",
        autoClose: 1500,
      });
    }
  };

  return (
    <div className="space-y-6 px-4 py-6">
      <div className="relative w-32 h-32 mx-auto mb-8">
        <FileUpload
          key={profilePicture}
          onUpload={(file) => file && handlePictureChange(file)}
          preview={profilePicture ?? undefined}
        >
          <Camera className="w-5 h-5" /> Upload File
        </FileUpload>
      </div>

      <div>
        <Label htmlFor="firstName">First name</Label>
        <Input
          id="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          className="mt-1"
        />
        <p className="text-sm text-gray-500 mt-1">
          This is how we'll refer to you and how you'll show up in the app.
        </p>
      </div>

      <div>
        <Label htmlFor="lastName">Last name</Label>
        <Input
          id="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          className="mt-1"
        />
      </div>

      <div>
        <Label htmlFor="displayName">Username</Label>
        <div className="relative mt-1">
          <Input
            id="displayName"
            value={formData.displayName}
            onChange={handleInputChange}
            className="pr-10"
          />
        </div>
      </div>

      <div>
        <div className="flex items-center gap-2 text-sm text-blue-600 mb-4">
          <span>{`https://tryregift.com/${firstName}-${lastName}`}</span>
          <button className="hover:bg-gray-100 p-1 rounded-full transition-colors">
            <Copy className="w-4 h-4" onClick={copyToCLipboard} />
          </button>
        </div>
      </div>

      <div>
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          className="mt-1"
        />
      </div>

      <div>
        <Button onClick={handleChangePassword}>Change Password</Button>
      </div>

      <div>
        <Label htmlFor="phone">Phone Number</Label>
        <Input
          id="phone"
          type="tel"
          value={formData.phone}
          onChange={handleInputChange}
          className="mt-1"
        />
      </div>

      <p className="text-sm text-gray-600 mt-8">
        By clicking Save, you confirm that you are authorized to use this mobile
        number and agree to receive SMS texts to update your mobile number and
        as otherwise permitted in our{" "}
        <Link to="#" className="text-blue-600">
          User Agreement
        </Link>
        . Carrier fees may apply.
      </p>

      <div className="pt-6 border-t">
        <Button onClick={handleSave} disabled={!isDirty}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ProfileTab;
