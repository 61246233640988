export const getBankDomain = (bank: string) => {
    // prettier-ignore
    const bankDomains: Record<string, string> = {
      "CHASE": "chase.com",
      "BANK OF AMERICA": "bankofamerica.com",
      "WELLS FARGO": "wellsfargo.com",
      "CITIBANK": "citi.com",
      "HSBC": "hsbc.com",
    };
    return bankDomains[bank] || "";
  };