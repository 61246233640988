import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PageTitle from "@/components/pageTitle";
import useLoading from "@/hooks/useLoading";
import { getAgency, updateAgency } from "@/communication/agencies";
import {
  StyledActionButtons,
  StyledInputsContainer,
  StyledInputsRowContainer,
} from "../style";
import { ReactComponent as ContentIcon } from "@/assets/icons/navbar/content.svg";
import Button from "@/components/buttons";
import DeleteIcon from "@/assets/icons/deleteIcon.svg";
import { useTouchField } from "@/hooks/useTouchField";
import InputField from "@/components/inputs/inputField";
import { StyledForm } from "./style";
import Table from "@/components/table";
import { UserDto } from "@/global/dtos/user.dto";
import { StyledPageHeader } from "@/global/styles/styles";

const AgencyDetails: FunctionComponent = () => {
  const { agencyId } = useParams();
  const { showLoading, hideLoading } = useLoading();
  const { isFieldTouched, handleFieldTouch } = useTouchField<FormValues>();
  const [creatorsList, setCreatorsList] = useState<UserDto[] | null>(null);

  type FormValues = {
    name: string | undefined;
    bankAccount?: string | undefined;
    routingNumber?: string | undefined;
    newBankAccount?: string | undefined;
    newRoutingNumber?: string | undefined;
  };

  const [tmpFormValues, setTmpFormValues] = useState<FormValues>({
    name: undefined,
    bankAccount: undefined,
    routingNumber: undefined,
    newBankAccount: undefined,
    newRoutingNumber: undefined,
  });

  const handleChange = (fieldName: keyof FormValues, value: string): void => {
    handleFieldTouch(fieldName);
    setTmpFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    if (fieldName === "name")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["name"]: value,
      }));
    if (fieldName === "newBankAccount")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["newBankAccount"]: value,
      }));
    if (fieldName === "newRoutingNumber")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["newRoutingNumber"]: value,
      }));
  };

  useEffect(() => {
    if (agencyId) {
      showLoading();
      getAgency(agencyId)
        .then((res: any) => {
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["name"]: res.data?.name,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["bankAccount"]: res.data?.bankAccount,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["routingNumber"]: res.data?.routingNumber,
          }));
          setCreatorsList && setCreatorsList(res.data.Users);
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
  }, [agencyId]);

  const handleUpdate = (): void => {
    showLoading();
    if (agencyId) {
      updateAgency(agencyId, tmpFormValues)
        .then(async () => {
          hideLoading();
          toast.success("Agency updated!", {
            position: "bottom-center",
            autoClose: 1500,
          });

          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["name"]: "",
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["bankAccount"]: "",
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["routingNumber"]: "",
          }));
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    }
  };

  return (
    <div className="page">
      <StyledPageHeader>
        <PageTitle title={`Agency details`} icon={<ContentIcon />} />
        <Button
          width="50px"
          variant="outlined"
          onClick={() => {
            console.log("aaaa");
          }}
        >
          <img src={DeleteIcon}></img>
        </Button>
      </StyledPageHeader>
      <StyledForm>
        <StyledInputsContainer>
          <InputField
            type="text"
            label="Agency name"
            value={tmpFormValues?.name || ""}
            onChange={(e) => handleChange("name", e.target.value)}
            placeholder="Name"
          />
          <StyledInputsRowContainer>
            <InputField
              type="text"
              label="Bank account number"
              value={tmpFormValues.bankAccount}
              disabled={true}
            />
            <InputField
              type="text"
              label="Bank routing number"
              value={tmpFormValues.routingNumber}
              disabled={true}
            />
          </StyledInputsRowContainer>
          <StyledInputsRowContainer>
            <InputField
              type="text"
              label="New bank account number"
              value={tmpFormValues.newBankAccount}
              onChange={(e) => handleChange("newBankAccount", e.target.value)}
            />
            <InputField
              type="text"
              label="New bank routing number"
              value={tmpFormValues.newRoutingNumber}
              onChange={(e) => handleChange("newRoutingNumber", e.target.value)}
            />
          </StyledInputsRowContainer>
          <StyledActionButtons>
            <Button
              width="152px"
              variant="outlined"
              onClick={() => {
                console.log("aaaa");
              }}
            >
              CANCEL
            </Button>
            <Button width="152px" variant="solid" onClick={handleUpdate}>
              Save
            </Button>
          </StyledActionButtons>
        </StyledInputsContainer>
      </StyledForm>
      {/* <Table
        items={
          creatorsList?.map((creator) => ({
            firstName: creator.firstName || "",
            lastName: creator.lastName || "",
            email: creator.email,
            displayName: creator.displayName,
          })) || []
        }
        columns={[
          { accessorKey: "firstName", header: "First name" },
          { accessorKey: "lastName", header: "Last name" },
          { accessorKey: "email", header: "Email" },
          { accessorKey: "displayName", header: "Display name" },
        ]}
        // handleRowClick={(e) => console.log("eeeee")}
      /> */}
    </div>
  );
};

export default AgencyDetails;
