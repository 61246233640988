import { FunctionComponent, useEffect, useState, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PageTitle from "@/components/pageTitle";
import {
  StyledActionButtons,
  StyledForm,
  StyledInputsContainer,
  StyledModalContent,
  StyledPageHeader,
  StyledProfilePictureContainer,
} from "./style";
import { ReactComponent as ContentIcon } from "@/assets/icons/navbar/content.svg";
import {
  deleteProduct,
  deleteProductPicture,
  getProduct,
  getProductPictures,
  updateProduct,
  uploadProductPictures,
} from "@/communication/product";
import { ProductDto } from "@/global/dtos/product.dto";
import InputField from "@/components/inputs/inputField";
import { useTouchField } from "@/hooks/useTouchField";
import Button from "@/components/buttons";
import Modal from "@/components/modal";
import useToggle from "@/hooks/useToggle";
import PictureUpload from "@/components/pictureUpload";
import {
  FileWithPreview,
  ProductCategory,
  ProductCondition,
} from "@/types/product";
import DeleteIcon from "@/assets/icons/deleteIcon.svg";
import SwitchField from "@/components/inputs/switch";
import { StyledSwitchContainer } from "../style";
import { capitalizeWords } from "@/functions/capitalLetter";
import useLoading from "@/hooks/useLoading";
import SelectField, { SelectOptionType } from "@/components/select";
import { getDiscount } from "@/functions/calculateDiscount";
import { UserDto } from "@/global/dtos/user.dto";
import { getSellers } from "@/communication/user";
import useUserStore from "@/store/user";
import { RoleCodes } from "@/global/roles";
import { calculations } from "@/functions/calculations";
import { StyledPageContainer } from "@/global/styles/styles";

const ProductDetails: FunctionComponent = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState<ProductDto | null>(null);
  const modal = useToggle();
  const { showLoading, hideLoading } = useLoading();
  const [usersList, setUsersList] = useState<UserDto[] | null>(null);
  const { role } = useUserStore();
  const [sellerName, setSellerName] = useState("");
  const [sellerOptions, setSellerOptions] = useState<
    { value: string; label: string }[] | undefined
  >(undefined);

  type TmpProductValues = {
    brand: string | undefined;
    name: string | undefined;
    description: string | undefined;
    blurb: string | undefined;
    price: string | undefined;
    isPublic: boolean;
    selfListed: boolean;
    discount: number | undefined;
    isDiscounted: boolean | undefined;
    size: string | undefined;
    material: string | undefined;
    countryOfOrigin: string | undefined;
    category: ProductCategory;
    condition: ProductCondition;
    sellerId: string | undefined;
    expectedCommission: string | undefined;
    compareAtPrice: string | undefined;
    quantity: number | undefined;
  };

  const [tmpProductValues, setTmpFormValues] = useState<TmpProductValues>({
    brand: undefined,
    name: undefined,
    description: undefined,
    blurb: undefined,
    price: undefined,
    isPublic: false,
    selfListed: false,
    discount: undefined,
    isDiscounted: undefined,
    size: undefined,
    material: undefined,
    countryOfOrigin: undefined,
    category: ProductCategory.CLOTHING,
    condition: ProductCondition.NEVER,
    sellerId: undefined,
    expectedCommission: undefined,
    compareAtPrice: undefined,
    quantity: undefined,
  });

  const { isFieldTouched, handleFieldTouch } =
    useTouchField<TmpProductValues>();

  const navigate = useNavigate();

  const handleChange = (
    fieldName: keyof TmpProductValues,
    value: string
  ): void => {
    handleFieldTouch(fieldName);
    setTmpFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    if (fieldName === "brand")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["brand"]: value }));
    if (fieldName === "name")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["name"]: value }));
    if (fieldName === "description")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["description"]: value,
      }));
    if (fieldName === "blurb")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["blurb"]: value }));
    if (fieldName === "price")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["price"]: value }));
    if (fieldName === "size")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["size"]: value }));
    if (fieldName === "material")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["material"]: value,
      }));
    if (fieldName === "countryOfOrigin")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["countryOfOrigin"]: value,
      }));
    if (fieldName === "expectedCommission")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["expectedCommission"]: value,
      }));
    if (fieldName === "compareAtPrice")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["compareAtPrice"]: value,
      }));
  };

  useEffect(() => {
    if (productId) {
      showLoading();
      getProduct(productId)
        .then((res: any) => {
          setProduct(res.data);
          hideLoading();
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["brand"]: res.data?.brand.name,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["name"]: res.data?.name,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["description"]: res.data?.description,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["blurb"]: res.data?.blurb,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["price"]: res.data?.price,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["isPublic"]: res.data?.isPublic,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["selfListed"]: res.data?.selfListed,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["discount"]: res.data?.discount,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["isDiscounted"]: res.data?.isDiscounted,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["size"]: res.data?.size,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["material"]: res.data?.material,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["countryOfOrigin"]: res.data?.countryOfOrigin,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["category"]: res.data?.category,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["condition"]: res.data?.condition,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["sellerId"]: res.data?.sellerId,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["expectedCommission"]: res.data?.expectedCommission,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["compareAtPrice"]: res.data?.compareAtPrice,
          }));
          setTmpFormValues((prevValues) => ({
            ...prevValues,
            ["quantity"]: res.data?.quantity,
          }));
        })
        .catch((error: any) => {
          hideLoading();
          console.log(error);
        });
    }
  }, [productId]);

  useEffect(() => {
    if (productId) {
      showLoading();
      getProductPictures(productId).then((res: any) => {
        const pictures = res.data.map((picture: any) => {
          return { id: picture.id, preview: picture.url };
        });
        setProductPictures(pictures);
        hideLoading();
      });
    }
  }, []);

  useEffect(() => {
    if (role === RoleCodes.ADMIN) {
      getSellers("READY").then((res: any) => {
        setUsersList && setUsersList(res.data);
      });
      const sellersOptions: SelectOptionType[] = [];
      getSellers("READY").then((res: any) => {
        for (const user of res.data) {
          sellersOptions.push({
            value: user?.id,
            label:
              user?.firstName && user?.lastName
                ? user?.firstName + " " + user?.lastName
                : user?.firstName ?? "",
          });
        }
      });
      getSellers("CREATED").then((res: any) => {
        for (const user of res.data) {
          sellersOptions.push({
            value: user?.id,
            label:
              user?.firstName && user?.lastName
                ? user?.firstName + " " + user?.lastName
                : user?.firstName ?? "",
          });
        }
      });
      setSellerOptions(sellersOptions);
    }
  }, [role]);

  useEffect(() => {
    const user = usersList?.find(
      (seller) => seller.id === tmpProductValues.sellerId
    );
    setSellerName(user ? `${user.firstName} ${user.lastName}` : "");
  }, [tmpProductValues.sellerId, usersList]);

  useEffect(() => {
    setTmpFormValues((prevValues) => ({
      ...prevValues,
      ["discount"]: getDiscount(
        tmpProductValues.category,
        tmpProductValues.condition
      ),
    }));
  }, [tmpProductValues.category, tmpProductValues.condition]);

  useEffect(() => {
    if (tmpProductValues.discount) {
      const discount = tmpProductValues.discount ?? 0;
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        price: (
          parseInt(tmpProductValues.compareAtPrice as string) *
          (1 - discount / 100)
        ).toFixed(2), // Ensure formatted price
      }));
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["expectedCommission"]: calculations(
          parseInt(tmpProductValues.price as string)
        ).toString(),
      }));
    }
  }, [tmpProductValues.compareAtPrice, tmpProductValues.discount]);

  useEffect(() => {
    if (tmpProductValues.price) {
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["expectedCommission"]: calculations(
          parseInt(tmpProductValues.price as string)
        ).toString(),
      }));
    }
  }, [tmpProductValues.price]);

  const handleUpdate = (): void => {
    if (productId) {
      showLoading();
      updateProduct(productId, tmpProductValues)
        .then(async (res: any) => {
          if (newPictures.length > 0) {
            savePictures();
          } else {
            hideLoading();
            toast.success("Product info updated!", {
              position: "bottom-center",
              autoClose: 1500,
            });
          }
          if (res.data?.name)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["name"]: res.data?.name,
            }));
          if (res.data?.description)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["description"]: res.data?.description,
            }));
          if (res.data?.blurb)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["blurb"]: res.data?.blurb,
            }));
          if (res.data?.price)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["price"]: res.data?.price,
            }));
          if (res.data?.isPublic)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["isPublic"]: res.data?.isPublic,
            }));
          if (res.data?.selfListed)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["selfListed"]: res.data?.selfListed,
            }));
          if (res.data?.discount)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["discount"]: res.data?.discount,
            }));
          if (res.data?.isDiscounted)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["isDiscounted"]: res.data?.isDiscounted,
            }));
          if (res.data?.size)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["size"]: "",
            }));
          if (res.data?.material)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["material"]: "",
            }));
          if (res.data?.countryOfOrigin)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["countryOfOrigin"]: "",
            }));
          if (res.data?.expectedCommission)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["expectedCommission"]: res.data?.expectedCommission,
            }));
          if (res.data?.compareAtPrice)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["compareAtPrice"]: res.data?.compareAtPrice,
            }));
          if (res.data?.quantity)
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["quantity"]: res.data?.quantity,
            }));
        })
        .catch((error: any) => {
          showLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    }
  };

  const handleDelete = (): void => {
    if (productId) {
      showLoading();
      deleteProduct(productId)
        .then(async (res: any) => {
          toast.success("Product successfully deleted!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          hideLoading();
          navigate(`/products`);
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    }
  };

  const [productPictures, setProductPictures] = useState<FileWithPreview[]>([]);
  const [newPictures, setNewPictures] = useState<FileWithPreview[]>([]);

  const handleRemoveFile = (file: FileWithPreview) => {
    setProductPictures((prevFiles) => prevFiles.filter((f) => f !== file));
    URL.revokeObjectURL(file.preview);
    if (file.id) {
      deleteProductPicture(file.id)
        .then((res: any) => {})
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const savePictures = () => {
    const formData = new FormData();
    newPictures.forEach((file) => {
      formData.append("files", file);
    });
    if (productId) {
      uploadProductPictures(productId, formData)
        .then((res: any) => {
          hideLoading();
          toast.success("Product info updated!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files).map((file) => {
        const preview = URL.createObjectURL(file);
        return Object.assign(file, { preview });
      });
      setNewPictures((prevFiles) => [...prevFiles, ...filesArray]);
      setProductPictures((prevFiles) => [...prevFiles, ...filesArray]);
    }
  };

  return (
    <>
      <Modal isOpen={modal.isOpen} handleClose={modal.close} width="44rem">
        <StyledModalContent>
          <StyledInputsContainer>
            <div>Are you sure you want to delete this product?</div>
            <StyledActionButtons>
              <Button width="152px" variant="outlined" onClick={modal.close}>
                CANCEL
              </Button>
              <Button width="152px" variant="solid" onClick={handleDelete}>
                DELETE
              </Button>
            </StyledActionButtons>
          </StyledInputsContainer>
        </StyledModalContent>
      </Modal>
      <div className="page">
        <StyledPageHeader>
          <PageTitle title={`Product details`} icon={<ContentIcon />} />
          <Button width="50px" variant="outlined" onClick={modal.open}>
            <img src={DeleteIcon}></img>
          </Button>
        </StyledPageHeader>
        <StyledPageContainer>
          <StyledForm>
            <StyledProfilePictureContainer>
              <PictureUpload
                onRemove={handleRemoveFile}
                onFileChange={handleFileChange}
                pictureList={productPictures}
                multiple={true}
                showActions={true}
              />
            </StyledProfilePictureContainer>
            <StyledInputsContainer>
              <InputField
                type="text"
                label="BRAND"
                value={tmpProductValues?.brand || ""}
                onChange={(e) =>
                  handleChange("brand", e.target.value.toUpperCase())
                }
                placeholder="BRAND"
              />
              <InputField
                type="text"
                label="Product name"
                value={tmpProductValues?.name || ""}
                onChange={(e) =>
                  handleChange("name", capitalizeWords(e.target.value))
                }
                placeholder="Product name"
              />
              <InputField
                type="textarea"
                label="Product description"
                value={tmpProductValues?.description || ""}
                onChange={(e) => handleChange("description", e.target.value)}
                placeholder="Product description"
              />
              <InputField
                type="text"
                label="Please describe why you love this product"
                value={tmpProductValues?.blurb || ""}
                onChange={(e) => handleChange("blurb", e.target.value)}
                placeholder="Please describe why you love this product"
              />
              <InputField
                type="text"
                label="Listed price"
                value={tmpProductValues?.price || ""}
                onChange={(e) => handleChange("price", e.target.value)}
                placeholder="Listed price"
              />
              <InputField
                type="text"
                label="expectedCommission"
                value={tmpProductValues?.expectedCommission || ""}
                onChange={(e) =>
                  handleChange("expectedCommission", e.target.value)
                }
                placeholder="expectedCommission"
              />
              <InputField
                type="text"
                label="Compare at price"
                value={tmpProductValues?.compareAtPrice || ""}
                onChange={(e) => handleChange("compareAtPrice", e.target.value)}
                placeholder="Compare at price"
              />
              <StyledSwitchContainer>
                <SwitchField
                  onChange={(value: boolean) => {
                    setTmpFormValues((prevValues) => ({
                      ...prevValues,
                      ["isPublic"]: value,
                    }));
                  }}
                  defaultChecked={tmpProductValues.isPublic || false}
                  label="Product is Anonymous"
                />
                <SwitchField
                  onChange={(value: boolean) => {
                    setTmpFormValues((prevValues) => ({
                      ...prevValues,
                      ["selfListed"]: value,
                    }));
                  }}
                  defaultChecked={tmpProductValues.selfListed || false}
                  label="Product is Self-Listed"
                />
                <SwitchField
                  onChange={(value: boolean) => {
                    setTmpFormValues((prevValues) => ({
                      ...prevValues,
                      ["isDiscounted"]: value,
                    }));
                  }}
                  defaultChecked={tmpProductValues.isDiscounted || false}
                  label="Product is Discounted"
                />
              </StyledSwitchContainer>
              <InputField
                type="text"
                label="Size"
                value={tmpProductValues?.size || ""}
                onChange={(e) => handleChange("size", e.target.value)}
                placeholder="Size"
              />
              <InputField
                type="text"
                label="Material Content"
                value={tmpProductValues?.material || ""}
                onChange={(e) => handleChange("material", e.target.value)}
                placeholder="Material Content"
              />
              <InputField
                type="text"
                label="Country of Origin"
                value={tmpProductValues?.countryOfOrigin || ""}
                onChange={(e) =>
                  handleChange("countryOfOrigin", e.target.value)
                }
                placeholder="Country of Origin"
              />
              <SelectField
                label="Category"
                options={Object.keys(ProductCategory).map((category) => {
                  return { value: category, label: category };
                })}
                onChange={(e) => {
                  setTmpFormValues({
                    ...tmpProductValues,
                    category: e.value,
                  });
                }}
                placeholder={tmpProductValues.category}
              />
              <SelectField
                label="Condition"
                options={Object.keys(ProductCondition).map((condition) => {
                  return { value: condition, label: condition };
                })}
                onChange={(e) => {
                  setTmpFormValues({
                    ...tmpProductValues,
                    condition: e.value,
                  });
                }}
                placeholder={tmpProductValues.condition}
              />
              {role === RoleCodes.ADMIN ? (
                <SelectField
                  label="Seller"
                  options={sellerOptions}
                  onChange={(e) => {
                    handleChange("sellerId", e.value);
                  }}
                  placeholder={sellerName}
                />
              ) : null}
              <InputField
                type="number"
                label="discount"
                value={tmpProductValues?.discount || 0}
                onChange={(e) => {
                  setTmpFormValues({
                    ...tmpProductValues,
                    discount: parseInt(e.target.value),
                  });
                }}
                placeholder={getDiscount(
                  tmpProductValues.category ?? ProductCategory.CLOTHING,
                  tmpProductValues.condition ?? ProductCondition.NEVER
                ).toString()}
                errorMessage={
                  tmpProductValues?.discount &&
                  (tmpProductValues?.discount < 25 ||
                    tmpProductValues?.discount > 80)
                    ? "Procenteges must be in range of 50 an 80 procent"
                    : ""
                }
              />
              <InputField
                type="number"
                label="quantity"
                value={tmpProductValues?.quantity || 1}
                onChange={(e) => {
                  setTmpFormValues({
                    ...tmpProductValues,
                    quantity: parseInt(e.target.value),
                  });
                }}
                placeholder={"1"}
              />
              <StyledActionButtons>
                <Button width="152px" variant="outlined" onClick={handleUpdate}>
                  CANCEL
                </Button>
                <Button width="152px" variant="solid" onClick={handleUpdate}>
                  SAVE
                </Button>
              </StyledActionButtons>
            </StyledInputsContainer>
          </StyledForm>
        </StyledPageContainer>
      </div>
    </>
  );
};
export default ProductDetails;
