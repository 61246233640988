import { FunctionComponent, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import PageTitle from "@/components/pageTitle";
import useUserStore from "@/store/user";
import { getBankDetails, getProfilePicture } from "@/communication/user";
import { FileWithPreview } from "@/types/product";
import useLoading from "@/hooks/useLoading";
import { getAllAgencies } from "@/communication/agencies";
import { AgencyDto } from "@/global/dtos/agency.dto";
import { UserStatus } from "@/global/dtos/user.dto";
import { getBankDomain } from "@/functions/getBankDomain";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import ProfileTab from "./profileTab";
import StorefrontTab from "./storefront";
import BankInfoTab from "./bankInfo";

const MyProfile: FunctionComponent = () => {
  const {
    id,
    displayName,
    firstName,
    lastName,
    email,
    bio,
    address,
    phone,
    ssn,
    dob,
    city,
    state,
    postalCode,
    status,
  } = useUserStore();

  type UserInfoFormValues = {
    displayName: string;
    email: string;
    oldPassword?: string;
    newPassword?: string;
    firstName: string;
    lastName: string;
    bio: string;
    profilePicture?: string;
    address?: string;
    phone?: string;
    ssn?: string;
    dob?: string;
    city?: string;
    state?: string;
    postalCode?: string;
  };

  const [userInfoFormValues, setUserInfoFormValues] =
    useState<UserInfoFormValues>({
      displayName: displayName || "",
      email: email || "",
      oldPassword: "",
      newPassword: "",
      firstName: firstName || "",
      lastName: lastName || "",
      bio: bio || "",
      address: undefined,
      phone: undefined,
      ssn: undefined,
      dob: undefined,
      city: undefined,
      state: undefined,
      postalCode: undefined,
    });

  const [activeTab, setActiveTab] = useState("profile");
  const { showLoading, hideLoading } = useLoading();
  const [agencyList, setAgencyList] = useState<AgencyDto[] | null>(null);
  const [tosAcceptance, setTosAcceptance] = useState(false);

  const windowUrl = window.location.search;
  const urlParams = new URLSearchParams(windowUrl);
  const bankInfoTab = urlParams.get("bank-info");
  const [bankLogoUrl, setBankLogoUrl] = useState<string | null>(null);
  const [bankName, setBankName] = useState<string>("");
  const [bankAccount, setBankAccount] = useState<string>("");

  useEffect(() => {
    if (bankInfoTab) {
      setActiveTab("bank-info");
    }
  }, [bankInfoTab]);

  useEffect(() => {
    if (displayName)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["displayName"]: displayName,
      }));
    if (email)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["email"]: email,
      }));
    if (firstName)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["firstName"]: firstName,
      }));
    if (lastName)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["lastName"]: lastName,
      }));
    if (bio)
      setUserInfoFormValues((prevValues) => ({ ...prevValues, ["bio"]: bio }));
    if (address)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["address"]: address,
      }));
    if (phone)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["phone"]: phone,
      }));
    if (ssn)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["ssn"]: ssn,
      }));
    if (dob)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["dob"]: dob,
      }));
    if (city)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["city"]: city,
      }));
    if (state)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["state"]: state,
      }));
    if (postalCode)
      setUserInfoFormValues((prevValues) => ({
        ...prevValues,
        ["postalCode"]: postalCode,
      }));
  }, [
    displayName,
    email,
    firstName,
    lastName,
    bio,
    address,
    phone,
    ssn,
    dob,
    city,
    state,
    postalCode,
  ]);

  useEffect(() => {
    showLoading();
    getProfilePicture(id)
      .then((res: any) => {
        const pictures = res.data.map((picture: any) => {
          return { id: picture.id, preview: picture.url };
        });
        setProfilePicture(pictures);
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    getAllAgencies().then((res: any) => {
      setAgencyList && setAgencyList(res.data);
    });
  }, []);

  useEffect(() => {
    if (status === UserStatus.READY || status === UserStatus.INVALID) {
      showLoading();
      getBankDetails()
        .then((res: any) => {
          if (res.data) {
            setBankAccount(res.data.bankAccount);
            setBankName(res.data.bankName);
            const bankDomain = getBankDomain(res.data?.bankName);
            if (bankDomain && bankDomain !== "") {
              setBankLogoUrl("https://logo.clearbit.com/${bankDomain}");
            }
          }
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
  }, [status]);

  const [profilePicture, setProfilePicture] = useState<FileWithPreview[]>([]);

  return (
    <>
      <PageTitle title="My Account" />
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <div className="flex justify-center mb-6">
            <TabsList className="grid w-full grid-cols-3 max-w-md">
              <TabsTrigger value="profile">Profile</TabsTrigger>
              <TabsTrigger value="storefront">Storefront</TabsTrigger>
              <TabsTrigger value="bank-info">Bank Info</TabsTrigger>
            </TabsList>
          </div>
          <TabsContent value="profile">
            <ProfileTab />
          </TabsContent>
          <TabsContent value="storefront">
            <StorefrontTab />
          </TabsContent>
          <TabsContent value="bank-info">
            <BankInfoTab />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
};
export default MyProfile;
