import { FunctionComponent, useState } from "react";
import { toast } from "react-toastify";
import { useTouchField } from "@/hooks/useTouchField";
import useIsEmail from "@/hooks/validation/useIsEmail";
import {
  StyledAuthWrapper,
  StyledForm,
  StyledAuthContent,
  StyledImage,
} from "@/pages/login/style";
import InfluencerImg from "@/assets/images/influencer.webp";
import { generateToken } from "@/communication/auth";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

type FormValues = {
  email: string;
};

const ForgotPassword: FunctionComponent = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
  });
  const emailErrorMessage = useIsEmail(formValues.email);

  const [isDirty, setIsDirty] = useState(false);

  const handleResetPassword = (): void => {
    if (emailErrorMessage) {
      toast.error("Invalid email!", {
        position: "bottom-center",
        autoClose: 1500,
      });
      setIsDirty(false);
    } else {
      generateToken(formValues.email)
        .then(() => {
          toast.success("Email sent! Check your inbox.", {
            position: "bottom-center",
            autoClose: 1500,
          });
          setIsDirty(false);
        })
        .catch((error) => {
          if (error.response.data.code === 1006) {
            toast.error("Invalid email!", {
              position: "bottom-center",
              autoClose: 1500,
            });
            setIsDirty(false);
          } else {
            toast.error("Something went wrong!", {
              position: "bottom-center",
              autoClose: 1500,
            });
          }
        });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.id]:
        e.target.id === "email" ? e.target.value.toLowerCase() : e.target.value,
    });
    setIsDirty(true);
  };

  return (
    <StyledAuthWrapper>
      <StyledAuthContent>
        <StyledImage>
          <img
            src={InfluencerImg}
            width="100%"
            height="250px"
            alt="profile pcture"
          />
        </StyledImage>
        <StyledForm>
          <h2>Enter your email</h2>
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={formValues.email}
              onChange={handleInputChange}
              className="mt-1"
            />
          </div>
          <div className="pt-6 border-t">
            <Button onClick={handleResetPassword} disabled={!isDirty}>
              Reset password
            </Button>
          </div>
        </StyledForm>
      </StyledAuthContent>
    </StyledAuthWrapper>
  );
};
export default ForgotPassword;
