// import { ReactComponent as DashboardIcon } from '@/assets/icons/navbar/dashboard.svg';
// import { ReactComponent as ProfileIcon } from '@/assets/icons/profileIcon.svg';
// import { ReactComponent as ContentIcon } from '@/assets/icons/navbar/content.svg';
// import { ReactComponent as UsersIcon } from '@/assets/icons/usersIcon.svg';
// import { ReactComponent as AgencyIcon } from '@/assets/icons/agencyIcon.svg';
// import { ReactComponent as WalletIcon } from '@/assets/icons/walletIcon.svg';
import { Wallet, User, Package, BarChart, Users } from "lucide-react";
import { NavbarLink } from '@/types/navbar';
import { RoleCodes } from '@/global/roles';
import useUserStore from "@/store/user";
export const useNavbarItems = (): Array<NavbarLink> => {

    const {
        role
      } = useUserStore();

    return [
        {
            title: "Wallet",
            link: '/wallet',
            icon: role === RoleCodes.SELLER ? Wallet : BarChart,
        },
        {
            title: "Products",
            link: '/products',
            icon: Package,
        },
        {
            title: "Account",
            link: '/my-profile',
            icon: User,
            excludeForRoles: [RoleCodes.ADMIN]
        },
        // {
        //     title: "Applicants",
        //     link: '/',
        //     icon: DashboardIcon,
        //     excludeForRoles: [RoleCodes.SELLER]
        // },
        {
            title: "Creators",
            link: '/creators',
            icon: Users,
            excludeForRoles: [RoleCodes.SELLER]
        },
        // {
        //     title: "Agencies",
        //     link: '/agencies',
        //     icon: AgencyIcon,
        //     excludeForRoles: [RoleCodes.SELLER]
        // },
    ];
};
