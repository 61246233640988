import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import PageTitle from "@/components/pageTitle";
import {
  StyledBankCard,
  StyledBankCardContainer,
  StyledPageContainer,
} from "@/global/styles/styles";
import { ReactComponent as WalletIcon } from "@/assets/icons/walletIcon.svg";
import { ReactComponent as AddIcon } from "@/assets/icons/addIcon.svg";
import useUserStore from "@/store/user";
import { RoleCodes } from "@/global/roles";
import {
  SellerAnalyticsDto,
  UserCommissionType,
  UserStatus,
} from "@/global/dtos/user.dto";
import {
  getBankDetails,
  getProfilePicture,
  getSellersAnalytics,
} from "@/communication/user";
import { SelectOptionType } from "@/components/select";
import {
  StyledFAQ,
  StyledFAQContainer,
  StyledFAQItems,
  StyledTableContainer,
} from "./style";
import { getTotalSales, startOnboarding } from "@/communication/payment";
import useLoading from "@/hooks/useLoading";
import { ReactComponent as ArrowDown } from "@/assets/icons/arrowDown.svg";
import { useNavigate } from "react-router-dom";
import { getBankDomain } from "@/functions/getBankDomain";
import { Card, CardContent } from "@/components/ui/card";
import WalletAdminContent from "./walletAdmin";
import { ImageIcon } from "lucide-react";

const Wallet: FunctionComponent = () => {
  const { role, id, status, displayName, firstName, lastName } = useUserStore();
  const navigate = useNavigate();
  const [usersList, setUsersList] = useState<SellerAnalyticsDto[] | null>(null);
  const [searchEvent, setSearchEvent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { showLoading, hideLoading } = useLoading();
  const [sellers, setSellers] = useState<SelectOptionType[]>([]);
  const [filterSeller, setFilterSeller] = useState<SelectOptionType | null>(
    null
  );
  const [commissionType, setCommissionType] = useState<SelectOptionType[]>([]);
  const [filterCommissionType, setFilterCommissionType] =
    useState<SelectOptionType | null>(null);
  const [totalSales, setTotalSales] = useState<number | null>(0);
  const [totalProductsSold, setTotalProductsSold] = useState<number | null>(0);
  const [incomingCommission, setIncomingCommission] = useState<number | null>(
    0
  );
  const [payoutsReceived, setPayoutsReceived] = useState<number | null>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [order, setOrder] = useState<SelectOptionType | null>(null);
  const [bankLogoUrl, setBankLogoUrl] = useState<string | null>(null);
  const [bankName, setBankName] = useState<string>("");
  const [bankAccount, setBankAccount] = useState<string>("");
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [userCommissionType, setUserCommissionType] = useState<string | null>(
    null
  );

  const fetchSellersAnalytics = async () => {
    showLoading();
    try {
      getSellersAnalytics(
        currentPage,
        searchValue,
        filterSeller?.value,
        filterCommissionType?.value,
        order?.value
      )
        .then((res) => {
          hideLoading();
          setUsersList([...res.data.users]);
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    if (role === RoleCodes.ADMIN) {
      showLoading();
      try {
        getSellersAnalytics(
          currentPage,
          searchValue,
          filterSeller?.value,
          filterCommissionType?.value,
          order?.value
        )
          .then((res) => {
            setUsersList([...res.data.users]);
            setTotalPages(res.data.totalPages);
            setCurrentPage(res.data.currentPage);
            const sellersOptions: SelectOptionType[] = [];
            for (const user of usersList || []) {
              sellersOptions.push({
                value: user?.id,
                label: user?.fullName,
              });
            }
            setSellers(sellersOptions);
            hideLoading();
          })
          .catch((error) => {
            console.log(error);
            hideLoading();
          });
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }
  }, [searchValue, filterCommissionType, filterSeller, currentPage, order]);

  useEffect(() => {
    if (status === UserStatus.READY || status === UserStatus.INVALID) {
      showLoading();
      getBankDetails()
        .then((res: any) => {
          if (res.data) {
            setBankAccount(res.data.bankAccount);
            setBankName(res.data.bankName);
            const bankDomain = getBankDomain(res.data?.bankName);
            if (bankDomain && bankDomain !== "") {
              setBankLogoUrl("https://logo.clearbit.com/${bankDomain}");
            }
          }
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
  }, [status]);
  useEffect(() => {
    if (role === RoleCodes.ADMIN) {
      //fetchUsers();
      const commissionTypeOptions: SelectOptionType[] = [];
      const commissionTypeArray: UserCommissionType[] =
        Object.values(UserCommissionType);
      for (const commissionType of commissionTypeArray) {
        commissionTypeOptions.push({
          value: commissionType,
          label: commissionType,
        });
      }
      setCommissionType(commissionTypeOptions);
      fetchSellersAnalytics();
      const sellersOptions: SelectOptionType[] = [];
      for (const user of usersList || []) {
        sellersOptions.push({
          value: user?.id,
          label: user?.fullName,
        });
      }
      setSellers(sellersOptions);
    }
    if (role === RoleCodes.SELLER) {
      getTotalSales(id)
        .then((res: any) => {
          setTotalSales(res.data.totalSales ?? 0);
          setIncomingCommission(res.data.incomingCommission ?? 0);
          setTotalProductsSold(res.data.totalProductsSold ?? 0);
          setPayoutsReceived(res.data.payoutsReceived ?? 0);
          setUserCommissionType(res.data.commissionType ?? "TIERED_V1");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [role]);

  const handleRowClick = (e): any => {
    // e.stopPropagation();
    console.log(e);
  };

  const handleAddBankClick = (e): any => {
    // e.stopPropagation();
    navigate(`/my-profile?bank-info=true`);
    console.log(e);
  };

  const handleValueChange = (e): void => {
    setSearchEvent(e);
    setSearchValue(e.target.value);
  };

  const openStripeUrl = (url: string) => {
    setTimeout(() => {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 1);
  };

  const handleRedirect = () => {
    showLoading();
    startOnboarding()
      .then((res: any) => {
        if (res.data.url) {
          openStripeUrl(res.data.url);
          hideLoading();
        } else {
          toast.error("Something went wrong! Please try again.", {
            position: "bottom-center",
            autoClose: 1500,
          });
          hideLoading();
        }
      })
      .catch((err: any) => {
        console.log(err);
        hideLoading();
      });
  };

  useEffect(() => {
    showLoading();
    if (id) {
      getProfilePicture(id)
        .then((res: any) => {
          const pictures = res.data.map((picture: any) => {
            return { id: picture.id, preview: picture.url };
          });
          setProfilePicture(pictures[0].preview);
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
  }, [id]);

  function StatCard({
    icon,
    title,
    value,
  }: {
    icon: string;
    title: string;
    value: string;
  }) {
    return (
      <Card className="bg-white">
        <CardContent className="p-4 flex items-center">
          <div className="text-2xl mr-4">{icon}</div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">{title}</h3>
            <p className="text-lg font-semibold">{value}</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      {role === RoleCodes.SELLER ? (
        <>
          <PageTitle title="My Wallet" icon={<WalletIcon />} />
          <div className="max-w-md mx-auto px-4">
            <div className="flex items-center">
              <div className="w-16 h-16 rounded-full overflow-hidden mr-4 mb-4">
                {profilePicture ? (
                  <img
                    src={profilePicture}
                    alt="Profile picture"
                    width={64}
                    height={64}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <ImageIcon className="w-10 h-10" />
                )}
              </div>
              <div>
                <h2 className="text-xl font-semibold">{`@${displayName}`}</h2>
                <p className="text-sm text-gray-500">{`${firstName} ${lastName}`}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mb-6">
              <StatCard
                icon="🛍️"
                title="Products Sold"
                value={`${totalProductsSold || 0}`}
              />
              <StatCard
                icon="💰"
                title="Earnings"
                value={`${totalSales || 0}`}
              />
              <StatCard
                icon="💸"
                title="Upcoming Payout"
                // value={`${incomingCommission || 0}`}
                value="pending"
              />
              {status === UserStatus.READY ? (
                <Card
                  className="bg-white cursor-pointer"
                  onClick={handleRedirect}
                >
                  <CardContent className="p-4 flex items-center">
                    {bankLogoUrl ? (
                      <img
                        src={bankLogoUrl}
                        className="mr-4"
                        alt={`Bank Logo`}
                      />
                    ) : (
                      <span className="mr-4 font-medium text-[#0074de]">
                        Bank Account Added
                      </span>
                    )}
                    <div>
                      <h3 className="text-base font-medium text-[#0074de]">
                        {bankName}
                      </h3>
                      <h3 className="text-base font-medium text-[#0074de]">{`****${bankAccount}`}</h3>
                    </div>
                  </CardContent>
                </Card>
              ) : (
                <Card
                  className="bg-white cursor-pointer"
                  onClick={handleRedirect}
                >
                  <CardContent className="p-4 flex items-center">
                    <AddIcon className="mr-4" />
                    <div>
                      <h3 className="text-base font-medium text-[#f26668]">
                        {status === UserStatus.INCOMPLETE
                          ? "Add bank account"
                          : "⛔ Update bank info"}
                      </h3>
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
            {/* <StyledBankCardContainer>
              {status === UserStatus.READY ? (
                <>
                  <StyledAddBankCard onClick={handleRedirect}>
                    <AddIcon />
                    <span>Add bank account</span>
                  </StyledAddBankCard>
                </>
              ) : status === UserStatus.INVALID ? (
                <StyledAddBankCard onClick={handleRedirect}>
                  <AddIcon />
                  <span>⛔ Update bank info</span>
                </StyledAddBankCard>
              ) : (
                <StyledBankCard onClick={handleRedirect}>
                  {bankLogoUrl ? (
                    <img src={bankLogoUrl} alt={`Bank Logo`} />
                  ) : (
                    <span>Bank Account Added</span>
                  )}
                  <span>{bankName}</span>
                  <span>{`****${bankAccount}`}</span>
                </StyledBankCard>
              )}
            </StyledBankCardContainer> */}
            <StyledPageContainer>
              <StyledFAQ>
                <h2>FAQ</h2>
                <StyledFAQContainer>
                  <StyledFAQItems>
                    <h3>
                      <button
                        type="button"
                        onClick={(e) => {
                          const button = e.currentTarget;
                          const chevron = button.querySelector(".chevron");

                          const content =
                            button.closest("h3")?.nextElementSibling;

                          button.classList.toggle("active");
                          chevron?.classList.toggle("rotated");
                          content?.classList.toggle("open");
                        }}
                        className={`accordion-button`}
                      >
                        How often do I get paid?
                        <span className="chevron">
                          <ArrowDown />
                        </span>
                      </button>
                    </h3>
                    <div className="accordion-content">
                      <p>
                        We issue payouts on a net 30 basis, meaning you’ll
                        receive payment 30 days after the end of the month in
                        which your items sell.
                      </p>
                      <p style={{ marginTop: "0.5rem" }}>
                        For example, commissions earned in March will be paid
                        out on April 30th.
                      </p>
                    </div>
                  </StyledFAQItems>
                  <StyledFAQItems>
                    <h3>
                      <button
                        type="button"
                        onClick={(e) => {
                          const button = e.currentTarget;
                          const chevron = button.querySelector(".chevron");

                          const content =
                            button.closest("h3")?.nextElementSibling;

                          button.classList.toggle("active");
                          chevron?.classList.toggle("rotated");
                          content?.classList.toggle("open");
                        }}
                        className={`accordion-button`}
                      >
                        How are my payouts calculated?
                        <span className="chevron">
                          <ArrowDown />
                        </span>
                      </button>
                    </h3>
                    <div className="accordion-content">
                      <p>
                        Your payouts are based on the sold price, minus a $3
                        handling fee, and the commission percentage for each
                        sale.
                      </p>

                      {userCommissionType !== UserCommissionType.CUSTOM_50 ? (
                        <>
                          <p
                            style={{
                              marginTop: "0.5rem",
                              marginBottom: "1rem",
                            }}
                          >
                            For a detailed breakdown, see the chart below:
                          </p>
                          <StyledTableContainer>
                            <table className="pricing-table">
                              <thead>
                                <tr className="pricing-header">
                                  <th className="pricing-cell">Sold Price</th>
                                  <th className="pricing-cell">Handling Fee</th>
                                  <th className="pricing-cell">
                                    Creator Commission
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>$1000+</td>
                                  <td>$3.00</td>
                                  <td>
                                    <div
                                      className="bar"
                                      style={{ width: "80%" }}
                                    >
                                      80%
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>$250 - $999.99</td>
                                  <td>$3.00</td>
                                  <td>
                                    <div
                                      className="bar"
                                      style={{ width: "70%" }}
                                    >
                                      70%
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>$100 - $249.99</td>
                                  <td>$3.00</td>
                                  <td>
                                    <div
                                      className="bar"
                                      style={{ width: "50%" }}
                                    >
                                      50%
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>$50 - $99.99</td>
                                  <td>$3.00</td>
                                  <td>
                                    <div
                                      className="bar"
                                      style={{ width: "40%" }}
                                    >
                                      40%
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>$25 - $49.99</td>
                                  <td>$3.00</td>
                                  <td>
                                    <div
                                      className="bar"
                                      style={{ width: "30%" }}
                                    >
                                      30%
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>$1 - $24.99</td>
                                  <td>$3.00</td>
                                  <td>
                                    <div
                                      className="bar"
                                      style={{ width: "20%" }}
                                    >
                                      20%
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </StyledTableContainer>
                        </>
                      ) : (
                        <>
                          <p
                            style={{
                              marginTop: "0.5rem",
                              marginBottom: "1rem",
                            }}
                          >
                            Your commission structure is: 50% commission on
                            price sold
                          </p>
                        </>
                      )}
                    </div>
                  </StyledFAQItems>
                </StyledFAQContainer>
              </StyledFAQ>
            </StyledPageContainer>
          </div>
        </>
      ) : (
        <>
          <PageTitle title="Wallet (Admin)" />
          <div className="max-w-7xl mx-auto px-4 md:px-8 py-6">
            <WalletAdminContent />
          </div>
        </>
      )}
    </>
  );
};

export default Wallet;
