import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Copy } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import useUserStore from "@/store/user";
import { Textarea } from "@/components/ui/textarea";
import useLoading from "@/hooks/useLoading";
import { getMyUtmDetails, updateSelf } from "@/communication/user";
import initFetch from "@/functions/init";

const StorefrontTab: FunctionComponent = () => {
  const { displayName, bio, firstName, lastName } = useUserStore();
  type StorefrontFormValues = {
    displayName: string;
    bio?: string;
    storeUrl: string;
  };
  const [formData, setFormData] = useState<StorefrontFormValues>({
    displayName: displayName ?? "",
    bio: bio,
    storeUrl: "",
  });
  const [isDirty, setIsDirty] = useState(false);
  const { showLoading, hideLoading } = useLoading();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setIsDirty(true);
  };

  const handleSave = () => {
    showLoading();
    updateSelf(formData)
      .then(() => {
        initFetch();
        hideLoading();
        toast.success("Profile updated!", {
          position: "bottom-center",
          autoClose: 1500,
        });
      })
      .catch((error: any) => {
        hideLoading();
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
    setIsDirty(false);
  };

  const copyToCLipboard = () => {
    navigator.clipboard.writeText(`https://tryregift.com/${formData.storeUrl}`);
    toast.success("Text copied!", {
      position: "bottom-center",
      autoClose: 1500,
    });
  };

  useEffect(() => {
    getMyUtmDetails().then((res) => {
      setFormData({ ...formData, storeUrl: res?.data });
    });
  }, []);

  return (
    <div className="space-y-6 px-4 py-6">
      <div>
        <Label htmlFor="storeName">Store Name</Label>
        <Input
          id="displayName"
          placeholder="Enter your store name"
          value={formData.displayName}
          onChange={handleInputChange}
          className="mt-1"
        />
      </div>

      <div>
        <Label htmlFor="storeDescription">Store Description</Label>
        <Textarea
          id="bio"
          placeholder="Describe your store"
          value={formData.bio}
          onChange={handleInputChange}
          className="mt-1"
        />
      </div>

      <div>
        <Label htmlFor="storeUrl">Store URL</Label>
        <div>
          <Input
            id="storeUrl"
            placeholder="Enter your store link"
            value={formData.storeUrl}
            onChange={handleInputChange}
            className="mt-1"
          />
          <div className="flex items-center gap-2 text-sm text-blue-600 mb-4">
            <span>{`https://tryregift.com/${formData.storeUrl}`}</span>
            <button className="hover:bg-gray-100 p-1 rounded-full transition-colors">
              <Copy className="w-4 h-4" onClick={copyToCLipboard} />
            </button>
          </div>
        </div>
      </div>

      <div className="pt-6 border-t">
        <Button onClick={handleSave} disabled={!isDirty}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default StorefrontTab;
