import { useState, useMemo, useEffect } from "react";
import { format } from "date-fns";
import { CalendarIcon, Search, ChevronDown } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import useLoading from "@/hooks/useLoading";
import { getSellersAnalytics } from "@/communication/user";
import { SellerAnalyticsDto } from "@/global/dtos/user.dto";
import useUserStore from "@/store/user";
import { RoleCodes } from "@/global/roles";

// Mock data for the table
// const initialData = [
//   {
//     id: 1,
//     name: "John Doe",
//     totalSales: 5000,
//     itemsSold: 100,
//     earningsPaid: 4000,
//     pendingBalance: 1000,
//     commissionStructure: "Standard",
//   },
//   {
//     id: 2,
//     name: "Jane Smith",
//     totalSales: 7500,
//     itemsSold: 150,
//     earningsPaid: 6000,
//     pendingBalance: 1500,
//     commissionStructure: "Premium",
//   },
//   {
//     id: 3,
//     name: "Bob Johnson",
//     totalSales: 3000,
//     itemsSold: 60,
//     earningsPaid: 2400,
//     pendingBalance: 600,
//     commissionStructure: "Standard",
//   },
//   {
//     id: 4,
//     name: "Alice Brown",
//     totalSales: 6200,
//     itemsSold: 120,
//     earningsPaid: 4960,
//     pendingBalance: 1240,
//     commissionStructure: "Premium",
//   },
//   {
//     id: 5,
//     name: "Charlie Wilson",
//     totalSales: 4100,
//     itemsSold: 82,
//     earningsPaid: 3280,
//     pendingBalance: 820,
//     commissionStructure: "Standard",
//   },
// ];

export default function WalletAdminContent() {
  const [date, setDate] = useState<{ from: Date; to: Date }>({
    from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    to: new Date(),
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const { showLoading, hideLoading } = useLoading();
  const [usersList, setUsersList] = useState<SellerAnalyticsDto[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterSeller, setFilterSeller] = useState("");
  const [filterCommissionType, setFilterCommissionType] = useState("");
  const [order, setOrder] = useState<string>("");
  const { role } = useUserStore();

  const fetchSellersAnalytics = async () => {
    showLoading();
    try {
      getSellersAnalytics(
        currentPage,
        searchValue,
        filterSeller,
        filterCommissionType,
        order
      )
        .then((res) => {
          hideLoading();
          setUsersList([...res.data.users]);
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    if (role === RoleCodes.ADMIN) {
      fetchSellersAnalytics();
    }
  }, [role]);

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="w-[300px] justify-start text-left font-normal"
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date.from ? (
                date.to ? (
                  <>
                    {format(date.from, "LLL dd, y")} -{" "}
                    {format(date.to, "LLL dd, y")}
                  </>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                <span>Pick a date range</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0 bg-white" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date.from}
              selected={date}
              //   onSelect={setDate}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>

        <div className="flex items-center space-x-2">
          <Input
            placeholder="Search creators..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-[250px]"
          />
          <Button variant="outline">
            <Search className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            {[
              "Creator Name",
              "Total Earnings (Lifetime)",
              "Items Sold",
              "Earnings Paid (lifetime)",
              "Pending Balance ($)",
              "Commission Structure",
            ].map((header) => (
              <TableHead
                key={header}
                className="cursor-pointer"
                onClick={() =>
                  handleSort(header.toLowerCase().replace(/\s+/g, ""))
                }
              >
                <div className="flex items-center">
                  {header}
                  <ChevronDown className="ml-2 h-4 w-4" />
                </div>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {usersList &&
            usersList.map((row: SellerAnalyticsDto) => (
              <TableRow key={row.id}>
                <TableCell>{row?.fullName ?? ""}</TableCell>
                <TableCell>${row?.totalEarned ?? ""}</TableCell>
                <TableCell>{row?.totalSold ?? ""}</TableCell>
                <TableCell>${row?.payedOut ?? ""}</TableCell>
                <TableCell>${row?.pendingPayout ?? ""}</TableCell>
                <TableCell>{row?.commissionType ?? ""}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
}
