import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import PageTitle from "@/components/pageTitle";
import {
  UpdateSellerDto,
  UserCommissionType,
  UserDto,
  UserStatus,
} from "@/global/dtos/user.dto";
import useLoading from "@/hooks/useLoading";
import {
  createSeller,
  createUtmData,
  getSellers,
  getUtmData,
  notifySeller,
  updateUtmData,
} from "@/communication/user";
import { ReactComponent as ContentIcon } from "@/assets/icons/navbar/content.svg";
import { AgencyDto } from "@/global/dtos/agency.dto";
import { getAllAgencies } from "@/communication/agencies";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Pencil, Check, X, Plus, BellRing } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { CreateUser } from "@/types/user";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SelectOptionType } from "@/components/select";

const CreatorsList: FunctionComponent = () => {
  const [usersList, setUsersList] = useState<UserDto[] | null>(null);
  const { showLoading, hideLoading } = useLoading();
  const [agencyList, setAgencyList] = useState<AgencyDto[] | null>(null);
  const [viewCreated, setViewCreated] = useState(false);
  // const [creatorId, setCreatorId] = useState<string | null>(null);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editedRow, setEditedRow] = useState<UpdateSellerDto | null>(null);
  const [utmDataExists, setUtmDataExists] = useState(false);
  const [webUrlDisabled, setWebUrlDisabled] = useState(false);
  const [commissionType, setCommissionType] = useState<SelectOptionType[]>([]);
  const [newCreator, setNewCreator] = useState<CreateUser>({
    firstName: "",
    lastName: "",
    email: "",
    instaHandle: "",
    tiktokHandle: "",
    displayName: "",
    password: "Test123#",
    commissionType: UserCommissionType.TIERED_V1,
  });

  type StorefrontFormValues = {
    websiteUrl: string;
    campaignSource: string;
    campaignMedium: string;
    campaignName: string;
    shortLink: string;
  };

  const [formData, setFormData] = useState<StorefrontFormValues>({
    websiteUrl: "",
    campaignSource: "",
    campaignMedium: "",
    campaignName: "",
    shortLink: "",
  });

  const fetchUsers = async () => {
    try {
      // Perform both API calls in parallel
      const [readyResponse, invalidResponse, incompleteResponse] =
        await Promise.all([
          getSellers("READY"),
          getSellers("INVALID"),
          getSellers("INCOMPLETE"),
        ]);

      // Extract the data from the responses
      const readyUserList: UserDto[] = readyResponse.data || [];
      const invalidUserList: UserDto[] = invalidResponse.data || [];
      const incompleteUserList: UserDto[] = incompleteResponse.data || [];

      // Merge the arrays and set the state
      setUsersList &&
        setUsersList([
          ...readyUserList,
          ...invalidUserList,
          ...incompleteUserList,
        ]);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    getAllAgencies().then((res: any) => {
      setAgencyList && setAgencyList(res.data);
    });
    const commissionTypeOptions: SelectOptionType[] = [];
    const commissionTypeArray: UserCommissionType[] =
      Object.values(UserCommissionType);
    for (const commissionType of commissionTypeArray) {
      commissionTypeOptions.push({
        value: commissionType,
        label: commissionType,
      });
    }
    setCommissionType(commissionTypeOptions);
  }, []);

  useEffect(() => {
    if (viewCreated) {
      getSellers("CREATED").then((res: any) => {
        setUsersList && setUsersList(res.data);
      });
    } else {
      fetchUsers();
    }
    getAllAgencies().then((res: any) => {
      setAgencyList && setAgencyList(res.data);
    });
  }, [viewCreated]);

  const handleAddCreator = (): void => {
    showLoading();
    createSeller(newCreator)
      .then(async (res: any) => {
        hideLoading();
        toast.success("Creator added!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        setFormData({
          websiteUrl: "",
          campaignSource: "",
          campaignMedium: "",
          campaignName: "",
          shortLink: "",
        });
      })
      .catch((error: any) => {
        hideLoading();
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
  };

  const handleNotify = (id: string): void => {
    showLoading();
    if (id) {
      notifySeller(id)
        .then(() => {
          hideLoading();
          toast.success("Creator notified!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    }

    hideLoading();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setEditedRow((prev) => ({
      ...prev!,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setEditingId(null);
    setEditedRow(null);
  };

  const handleEdit = (id: string) => {
    setEditingId(id);
    console.log(id);
    const rowToEdit = usersList?.find((user) => user.id === id);
    if (rowToEdit) {
      setEditedRow({ ...rowToEdit });
    }
  };

  const handleNewCreatorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCreator({ ...newCreator, [e.target.name]: e.target.value });
  };

  const handleUpdateCreatorUtmChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUtmChange = (id: string) => {
    showLoading();
    getUtmData(id).then((res: any) => {
      if (
        res.data.websiteUrl ||
        res.data.campaignSource ||
        res.data.campaignMedium ||
        res.data.campaignName ||
        res.data.shortLink
      ) {
        setUtmDataExists(true);
      } else {
        setUtmDataExists(false);
      }
      setFormData({
        websiteUrl: res.data.websiteUrl ?? "",
        campaignSource: res.data.campaignSource ?? "",
        campaignMedium: res.data.campaignMedium ?? "",
        campaignName: res.data.campaignName ?? "",
        shortLink: res.data.shortLink ?? "",
      });
      if (res.data.websiteUrl && res.data.websiteUrl !== "") {
        setWebUrlDisabled(true);
      } else {
        setWebUrlDisabled(false);
      }
      hideLoading();
    });
  };

  const handleUpdateUtmData = (id: string) => {
    showLoading();
    if (utmDataExists) {
      updateUtmData(id, formData)
        .then((res) => {
          hideLoading();
          toast.success("Updated UTM data!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    } else {
      createUtmData(id, formData)
        .then((res) => {
          hideLoading();
          toast.success("Updated UTM data!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    }
  };

  return (
    <>
      <PageTitle title="Creators" icon={<ContentIcon />} />
      <div className="space-y-4">
        <div className="flex justify-end">
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Switch
                id="created-view"
                checked={viewCreated}
                onCheckedChange={setViewCreated}
              />
              <Label htmlFor="list-view">
                {viewCreated ? `Created View` : `Ready View`}
              </Label>
            </div>
            <Dialog>
              <DialogTrigger asChild>
                <Button>
                  <Plus className="mr-2 h-4 w-4" /> Add New Creator
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px] bg-white">
                <DialogHeader>
                  <DialogTitle>Add New Creator</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label className="text-right">First name</Label>
                    <Input
                      name="firstName"
                      value={newCreator.firstName}
                      onChange={handleNewCreatorChange}
                      className="col-span-3"
                    ></Input>
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label className="text-right">Last name</Label>
                    <Input
                      name="lastName"
                      value={newCreator.lastName}
                      onChange={handleNewCreatorChange}
                      className="col-span-3"
                    ></Input>
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label className="text-right">Email</Label>
                    <Input
                      name="email"
                      value={newCreator.email}
                      onChange={handleNewCreatorChange}
                      className="col-span-3"
                    ></Input>
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label className="text-right">Display name</Label>
                    <Input
                      name="displayName"
                      value={newCreator.displayName}
                      onChange={handleNewCreatorChange}
                      className="col-span-3"
                    ></Input>
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label className="text-right">Instagram</Label>
                    <Input
                      name="instaHandle"
                      value={newCreator.instaHandle}
                      onChange={handleNewCreatorChange}
                      className="col-span-3"
                    ></Input>
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label className="text-right">TikTok</Label>
                    <Input
                      name="tiktokHandle"
                      value={newCreator.tiktokHandle}
                      onChange={handleNewCreatorChange}
                      className="col-span-3"
                    ></Input>
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label className="text-right">Commission Type</Label>
                    <Select
                      value={newCreator.commissionType}
                      onValueChange={(e: UserCommissionType) => {
                        setNewCreator({ ...newCreator, commissionType: e });
                      }}
                    >
                      <SelectTrigger className="col-span-3">
                        <SelectValue placeholder="Select a commissionType" />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        <SelectGroup>
                          <SelectLabel>Commission Type</SelectLabel>
                          {commissionType.map((condition) => (
                            <SelectItem value={condition.value}>
                              {condition.label}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <DialogClose>
                  <Button onClick={handleAddCreator}>Add Creator</Button>
                </DialogClose>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              {[
                "First name",
                "Last name",
                "Email",
                "Instagram",
                "TikTok",
                "Display name",
                "Bank Added",
                "Commission Type",
                "Actions",
              ].map((header) => (
                <TableHead key={header}>{header}</TableHead>
              ))}
            </TableRow>
          </TableHeader>
          {usersList && (
            <TableBody>
              {usersList.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Input
                      name="firstName"
                      value={row.firstName}
                      onChange={handleChange}
                      className="w-full"
                    ></Input>
                  </TableCell>
                  <TableCell>
                    <Input
                      name="lastName"
                      value={row.lastName}
                      onChange={handleChange}
                      className="w-full"
                    ></Input>
                  </TableCell>
                  <TableCell>
                    <Input
                      name="email"
                      value={row.email}
                      onChange={handleChange}
                      className="w-full"
                    ></Input>
                  </TableCell>
                  <TableCell>
                    <Input
                      name="instaHandle"
                      value={row.instaHandle}
                      onChange={handleChange}
                      className="w-full"
                    ></Input>
                  </TableCell>
                  <TableCell>
                    <Input
                      name="tiktokHandle"
                      value={row.tiktokHandle}
                      onChange={handleChange}
                      className="w-full"
                    ></Input>
                  </TableCell>
                  <TableCell>
                    <Input
                      name="displayName"
                      value={row.displayName}
                      onChange={handleChange}
                      className="w-full"
                    ></Input>
                  </TableCell>
                  <TableCell>
                    <Input
                      name="Bank Added"
                      value={
                        row.status === UserStatus.READY
                          ? "Complete"
                          : row.status === UserStatus.INVALID
                            ? "Incomplete"
                            : "No"
                      }
                      onChange={handleChange}
                      className="w-full"
                    ></Input>
                  </TableCell>
                  <TableCell className="w-40">
                    <Select
                      value={row.commissionType}
                      onValueChange={(e: UserCommissionType) => {
                        console.log(e);
                        // handleChange((prev) => ({
                        //   ...prev,
                        //   commissionType: e,
                        // }));
                      }}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a commissionType" />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        <SelectGroup>
                          <SelectLabel>Commission Type</SelectLabel>
                          {commissionType.map((condition) => (
                            <SelectItem value={condition.value}>
                              {condition.label}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </TableCell>
                  {/* {Object.keys(row)
                    .filter((key) => key !== "id")
                    .map((key) => (
                      <TableCell key={key}>
                        {editingId === row.id ? (
                          <Input
                            name={key}
                            value={editedRow[key] || ""}
                            onChange={handleChange}
                            className="w-full"
                          />
                        ) : (
                          row[key as keyof typeof row]
                        )}
                      </TableCell>
                    ))} */}
                  {viewCreated ? (
                    <TableCell>
                      {editingId === row.id ? (
                        <div className="flex space-x-2">
                          <Button size="sm" onClick={() => console.log(row.id)}>
                            <Check className="h-4 w-4" />
                          </Button>
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={handleCancel}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      ) : (
                        <div className="flex space-x-2">
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => handleEdit(row.id)}
                          >
                            <Pencil className="h-4 w-4" />
                          </Button>
                          <Dialog>
                            <DialogTrigger asChild>
                              <Button size="sm" variant="outline">
                                <BellRing className="h-4 w-4" />
                              </Button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-[425px] bg-white">
                              <DialogHeader>
                                <DialogTitle>Notify Creator</DialogTitle>
                              </DialogHeader>
                              <div className="grid gap-4 py-4">
                                <div className="items-center">
                                  <Label className="text-right">
                                    Are you sure you want to notify creator?
                                  </Label>
                                </div>
                                <div className="flex space-x-2">
                                  <Button onClick={() => handleNotify(row.id)}>
                                    Yes
                                  </Button>
                                  <DialogClose asChild>
                                    <Button type="button" variant="secondary">
                                      Close
                                    </Button>
                                  </DialogClose>
                                </div>
                              </div>
                            </DialogContent>
                          </Dialog>
                        </div>
                      )}
                    </TableCell>
                  ) : (
                    <TableCell>
                      <div className="flex space-x-2">
                        <Dialog>
                          <DialogTrigger asChild>
                            <Button
                              size="sm"
                              variant="outline"
                              onClick={() => handleUtmChange(row.id)}
                            >
                              Edit utm
                            </Button>
                          </DialogTrigger>
                          <DialogContent className="sm:max-w-[425px] bg-white">
                            <DialogHeader>
                              <DialogTitle>Update UTM data</DialogTitle>
                            </DialogHeader>
                            <div className="grid gap-4 py-4">
                              <div className="grid grid-cols-4 items-center gap-4">
                                <Label className="text-right">
                                  Website URL
                                </Label>
                                <Input
                                  name="websiteUrl"
                                  value={formData.websiteUrl}
                                  onChange={handleUpdateCreatorUtmChange}
                                  className="col-span-3"
                                  disabled={webUrlDisabled}
                                ></Input>
                              </div>
                              <div className="grid grid-cols-4 items-center gap-4">
                                <Label className="text-right">
                                  Campaign Source
                                </Label>
                                <Input
                                  name="campaignSource"
                                  value={formData.campaignSource}
                                  onChange={handleUpdateCreatorUtmChange}
                                  className="col-span-3"
                                ></Input>
                              </div>
                              <div className="grid grid-cols-4 items-center gap-4">
                                <Label className="text-right">
                                  Campaign Medium
                                </Label>
                                <Input
                                  name="campaignMedium"
                                  value={formData.campaignMedium}
                                  onChange={handleUpdateCreatorUtmChange}
                                  className="col-span-3"
                                ></Input>
                              </div>
                              <div className="grid grid-cols-4 items-center gap-4">
                                <Label className="text-right">
                                  Campaign Name
                                </Label>
                                <Input
                                  name="campaignName"
                                  value={formData.campaignName}
                                  onChange={handleUpdateCreatorUtmChange}
                                  className="col-span-3"
                                ></Input>
                              </div>
                              <div className="grid grid-cols-4 items-center gap-4">
                                <Label className="text-right">
                                  Short link custom part
                                </Label>
                                <Input
                                  name="shortLink"
                                  value={formData.shortLink}
                                  onChange={handleUpdateCreatorUtmChange}
                                  className="col-span-3"
                                ></Input>
                              </div>
                              <div className="grid grid-cols-4 items-center gap-4">
                                <Label className="text-right">
                                  Shortened link
                                </Label>
                                <span>{`https://tryregift.com/${formData.shortLink}`}</span>
                              </div>
                            </div>
                            <div className="flex space-x-2">
                              <Button
                                onClick={() => handleUpdateUtmData(row.id)}
                              >
                                {utmDataExists
                                  ? "Update UTM data"
                                  : "Create UTM data"}
                              </Button>
                              <DialogClose asChild>
                                <Button type="button" variant="secondary">
                                  Close
                                </Button>
                              </DialogClose>
                            </div>
                          </DialogContent>
                        </Dialog>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </div>
    </>
  );
};

export default CreatorsList;
