// export type Product = {
//     id: number;
//     name: string;
//     description: string;
//     price: string;
//     properties: object;
// };

export interface FileWithPreview extends File {
    preview: string;
    id?: string;
};

export enum ProductCategory {
    BEAUTY = "BEAUTY",
    CLOTHING = "CLOTHING",
    LUXURY = "LUXURY",
    HOME = "HOME",
    ELECTRONICS = "ELECTRONICS",
    FITNESS = "FITNESS",
};

export enum ProductCondition {
    NEVER = "NEVER",
    ONCE = "ONCE",
    LIGHTLY = "LIGHTLY",
};

export interface Product {
    id: string | undefined;
    brand: string | undefined;
    name: string | undefined;
    description: string | undefined;
    price: string | undefined;
    isPublic: boolean;
    selfListed: boolean;
    discount: number | undefined;
    expectedCommission: string | undefined;
    isDiscounted: boolean | undefined;
    category: ProductCategory;
    condition: ProductCondition;
    sellerId: string | undefined;
    compareAtPrice: string | undefined;
    quantity: number | undefined;
}