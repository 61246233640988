import styled from 'styled-components';

export const StyledPageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
`;

export const StyledFiltering = styled.div`
    display: flex;
    gap: 10px;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        flex-direction: column;
    }
`;

export const StyledBankCardContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 2rem;
    span {
        color: #6b7280;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    img {
        width: 100%;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
`;

export const StyledBankCard = styled.div`
    border: 0.07rem solid ${(props) => props.theme.palette.gray};
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    width: 150px;
    cursor: pointer;
    background: white;
    span {
        max-width: 70%;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.75rem;
        color: #0074de;
        font-weight: 700;
        margin: 15px 0; 
    }
`;

export const StyledAddBankCard = styled.div`
    border: 0.07rem solid ${(props) => props.theme.palette.gray};
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    width: 150px;
    cursor: pointer;
    gap: 20px;
    background: white;
    span {
        max-width: 70%;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.75rem;
        color: #f26668;
        font-weight: 700;
    }
`;

export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const StyledInputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    textarea {
        height: 160px;
    }
`;

export const StyledActionButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;