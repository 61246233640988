import { FunctionComponent, useEffect, useState, useRef } from "react";
import { Search, Filter, ChevronDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdownMenu";
import { ProductCategory } from "@/types/product";

export type SellerFilterOption = {
  value: string;
  label: string;
};

interface ProductControlsProps {
  onSearch: (term: string) => void;
  onFilterCategory: (category: string) => void;
  onFilterSeller: (creator: string) => void;
  onSort: (method: string) => void;
  sellers?: SellerFilterOption[];
}

const ProductsControls: FunctionComponent<ProductControlsProps> = ({
  onSearch,
  onFilterCategory,
  onFilterSeller,
  onSort,
  sellers,
}: ProductControlsProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = () => {
    onSearch(searchTerm);
  };
  const categories: string[] = Object.values(ProductCategory);

  return (
    <div className="flex items-center space-x-2">
      <div className="relative">
        <Input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pr-8"
        />
        <Button
          onClick={handleSearch}
          variant="ghost"
          className="absolute right-0 top-0 h-full px-3"
        >
          <Search className="h-4 w-4" />
        </Button>
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">
            <Filter className="h-4 w-4 mr-2" />
            Category
            <ChevronDown className="h-4 w-4 ml-2" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="bg-white">
          <DropdownMenuItem onSelect={() => onFilterCategory("ALL")}>
            ALL
          </DropdownMenuItem>
          {categories.map((category) => (
            <DropdownMenuItem onSelect={() => onFilterCategory(category)}>
              {category}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      {sellers && sellers.length > 0 ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              <Filter className="h-4 w-4 mr-2" />
              Creator
              <ChevronDown className="h-4 w-4 ml-2" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-white max-h-60 overflow-y-auto">
            <DropdownMenuItem onSelect={() => onFilterSeller("ALL")}>
              ALL
            </DropdownMenuItem>
            {sellers.map((seller) => (
              <DropdownMenuItem onSelect={() => onFilterSeller(seller.value)}>
                {seller.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : null}

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">
            Sort
            <ChevronDown className="h-4 w-4 ml-2" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="bg-white">
          <DropdownMenuItem onSelect={() => onSort("alphabeticalAsc")}>
            Name (A-Z)
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => onSort("alphabeticalDsc")}>
            Name (Z-A)
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => onSort("priceAsc")}>
            Price (Low to High)
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => onSort("priceDsc")}>
            Price (High to Low)
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => onSort("dateAsc")}>
            Date (New to Old)
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => onSort("dateDsc")}>
            Date (Old to New)
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ProductsControls;
